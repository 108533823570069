import constate from "constate";
import { useState } from "react";
import { IBeneficiariesPixKey, IBeneficiaryFund } from "~/services/api/beneficiaries";
import { BankAccount, IBeneficiary, PixKey } from "~/types";

const [BeneficiaryProvider, useBeneficiary] = constate(() => {

  const [Beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);

  const [beneficiaryAccounts, setBeneficiaryAccounts] = useState<BankAccount[]>()

  const [openModalDeleteBeneficiary, setOpenModalDeleteBeneficiary] =
    useState<boolean>(false);

  const [beneficiaryDetails, setBeneficiaryDetails] = useState<IBeneficiary>();
  const [editModalBeneficiary, setEditModalBeneficiary] =
    useState<boolean>(false);

  const [editStatusBeneficiaryModal, setEditStatusBeneficiaryModal] =
    useState<boolean>(false);

  const [addBeneficiaryFundModal, setAddBeneficiaryFundModal] =
    useState<boolean>(false);

  const [approveBeneficiaryFundModal, setApproveBeneficiaryFundModal] =
    useState<boolean>(false);

  const [reproveBeneficiaryFundModal, setReproveBeneficiaryFundModal] =
    useState<boolean>(false);

  const [beneficiaryFundDetails, setBeneficiaryFundDetails] =
    useState<IBeneficiaryFund>();

  const [fundsBeneficiaryRender, setFundsBeneficiaryRender] =
    useState<IBeneficiaryFund[]>();


  const [accountBeneficiary, setAccountBeneficiary] = useState<BankAccount[]>();

  const [deleteAccountModalBeneficiary, setDeleteModalAccountBeneficiary] =
    useState<boolean>(false);

  const [editAccountModalBeneficiary, seteditAccountModalBeneficiary] =
    useState<boolean>(false);


  const [addkeyPixModal, setaddkeyPixModal] = useState<boolean>(false);
  const [pixkeyUser, setpixkeyUser] = useState<PixKey>();
  const [deleteModalKeyPix, setdeleteModalKeyPix] = useState<boolean>(false);
  const [renderPixKeys, setRenderPixKeys] = useState<IBeneficiariesPixKey[]>()


  const [openApproveBeneficiaryModal, setOpenApproveBeneficiaryModal] =
    useState<boolean>(false);

  const [openReproveBeneficiaryModal, setOpenReproveBeneficiaryModal] =
    useState<boolean>(false);

  const [
    appApproveAccountBeneficiaryModal,
    setAppApproveAccountBeneficiaryModal,
  ] = useState<boolean>(false);

  const [
    appReproveAccountBeneficiaryModal,
    setappReproveAccountBeneficiaryModal,
  ] = useState<boolean>(false);


  return {
    reproveBeneficiaryFundModal,
    setReproveBeneficiaryFundModal,
    appReproveAccountBeneficiaryModal,
    setappReproveAccountBeneficiaryModal,
    fundsBeneficiaryRender,
    setFundsBeneficiaryRender,
    beneficiaryFundDetails,
    setBeneficiaryFundDetails,
    approveBeneficiaryFundModal,
    setApproveBeneficiaryFundModal,
    addBeneficiaryFundModal,
    setAddBeneficiaryFundModal,
    editStatusBeneficiaryModal,
    setEditStatusBeneficiaryModal,
    Beneficiaries,
    setBeneficiaries,
    openModalDeleteBeneficiary,
    setOpenModalDeleteBeneficiary,
    setBeneficiaryDetails,
    beneficiaryDetails,
    setEditModalBeneficiary,
    editModalBeneficiary,
    setDeleteModalAccountBeneficiary,
    deleteAccountModalBeneficiary,
    seteditAccountModalBeneficiary,
    editAccountModalBeneficiary,
    setAccountBeneficiary,
    accountBeneficiary,
    setaddkeyPixModal,
    addkeyPixModal,
    setpixkeyUser,
    pixkeyUser,
    renderPixKeys,
    setRenderPixKeys,
    setdeleteModalKeyPix,
    deleteModalKeyPix,
    openApproveBeneficiaryModal,
    setOpenApproveBeneficiaryModal,
    openReproveBeneficiaryModal,
    setOpenReproveBeneficiaryModal,
    setAppApproveAccountBeneficiaryModal,
    appApproveAccountBeneficiaryModal,
    beneficiaryAccounts,
    setBeneficiaryAccounts
  };
});

export { BeneficiaryProvider };

export default useBeneficiary;
