import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
  TableToolbarSearch,
} from "@carbon/react";
import {
  TrashCan,
  Add,
  Save,
  Download,
  Renew,
  Edit,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
// import { useFormik } from "formik";
import Padding from "~/components/Padding";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import { permissions } from "~/services/permissions";
import { getFundsAPI } from "~/services/api/fund";
import useFund from "~/hooks/useFund";
import NewFundModal from "./NewFundModal";
import DeleteFundModal from "./DeleteFundModal";
import EditFundModal from "./EditFundModal";
import Badge from "~/components/Badge";
import { useFormik } from "formik";
// import DeleteProfileModal from "./DeleteProfileModal";

const tableHeadersProfile: string[] = [
  "Nome (Abreviado)",
  "CNPJ",
  "Nome (Completo)",
  "Status",
  "Autenticação",
  "",
  "",
];

const canReadFunds = permissions.includes("can_read_funds");
const canCreateFunds = permissions.includes("can_create_funds");
// const canEditProfiles = permissions.includes("can_edit_profiles")
const canDeleteFunds = permissions.includes("can_delete_funds");

const Funds = () => {
  const { setLoading } = useLoading();

  const {
    setFunds,
    funds,
    openNewFundModal,
    setOpenNewFundModal,
    setOpenDeleteFundModal,
    openDeleteFundModal,
    setFundDetails,
    setOpenEditFundModal,
    openEditFundModal,
  } = useFund();

  const { values, handleChange } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: { search: string }) => {
      console.log(values);
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const paginatedFunds = useMemo(() => {
    const lowerSearchTerm = values.search.toLowerCase();
    const searchIsNumeric = onlyNumbers(values.search).length > 0; // Verifica se a busca contém apenas números

    const filteredFunds = funds.filter((fund) => {
      // Obtenha os dados do fundo, tratando casos de valores indefinidos
      const fundName = fund.name?.toLowerCase() || "";
      const fundShortName = fund.raw_data?.short_name?.toLowerCase() || "";
      const fundDocumentNumber =
        onlyNumbers(fund.raw_data?.document_number) || "";

      // Se o termo de busca for numérico, apenas busca no document_number
      if (searchIsNumeric) {
        return fundDocumentNumber.includes(onlyNumbers(values.search));
      }

      // Caso contrário, busca no nome do fundo e no nome curto
      return (
        fundName.includes(lowerSearchTerm) ||
        fundShortName.includes(lowerSearchTerm)
      );
    });

    // Lógica de paginação
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredFunds.slice(start, end);
  }, [currentPage, itemsPerPage, funds, values]);

  const fetchFunds = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setFunds]);

  useEffect(() => {
    fetchFunds();
  }, [fetchFunds]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Fundos</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadFunds && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchFunds();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadFunds && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar usuários
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              <TableToolbarSearch
                tabIndex={0}
                onChange={handleChange}
                value={values.search}
                id="search"
                placeholder="Pesquisar"
                persistent
              />
              {/* {user.type === "tool_manager" && (
                  <Tooltip defaultOpen label="Exportar relatório" align="top">
                    <Button
                      kind="ghost"
                      hasIconOnly
                      renderIcon={Report}
                      id="filter"
                    />
                  </Tooltip>
                )}
                <Tooltip defaultOpen label="Filtrar" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Filter}
                    id="filter"
                    onClick={() => setOpenModal(true)}
                  />
                </Tooltip> */}

              {canCreateFunds && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    setOpenNewFundModal(true);
                  }}
                  kind="primary"
                >
                  Novo fundo
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadFunds && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                  /> */}
                  {tableHeadersProfile.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedFunds?.length === 0 ? (
                  <TableCell colSpan={tableHeadersProfile.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum fundo até o momento</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedFunds?.length > 0 ? (
                  paginatedFunds?.map((fund, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                        ariaLabel={""}
                      /> */}

                      <TableCell>{fund?.raw_data?.short_name}</TableCell>
                      <TableCell>
                        {formatCPFCNPJ(fund?.raw_data?.document_number)}
                      </TableCell>

                      <TableCell style={{ maxWidth: "300px" }}>
                        {fund?.name}
                      </TableCell>
                      <TableCell>
                        {fund?.raw_data?.active ? (
                          <Badge
                            color="#CCEFDF"
                            textColor="#00B360"
                            label="Ativo"
                          />
                        ) : (
                          <Badge
                            label="Inativo"
                            color="#e3e3e3"
                            textColor="#696868"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {fund.raw_data.authenticated ? (
                          <Badge
                            color="#bacbff"
                            textColor="#1906ec"
                            label="Autenticado"
                          />
                        ) : (
                          <Badge
                            label="Não autenticado"
                            color="#e3e3e3"
                            textColor="#696868"
                          />
                        )}
                      </TableCell>

                      <TableCell>
                        {/* <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription={
                            fund.raw_data.authenticated
                              ? "Re-autenticar"
                              : "Autenticar"
                          }
                          renderIcon={Security}
                          id="filter"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenEditFundModal(true);
                            setFundDetails(fund);
                          }}
                        /> */}
                        <div></div>
                      </TableCell>
                      <TableCell>
                        <TableCell style={{ padding: "0" }}>
                          {canDeleteFunds && (
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription="Editar"
                              renderIcon={Edit}
                              id="filter"
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenEditFundModal(true);
                                setFundDetails(fund);
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "0" }}>
                          {canDeleteFunds && (
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription="Excluir"
                              renderIcon={TrashCan}
                              id="filter"
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenDeleteFundModal(true);
                                setFundDetails(fund);
                              }}
                            />
                          )}
                        </TableCell>
                      </TableCell>
                      {/* <TableCell>{profile.area}</TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  paginatedFunds?.map((fund, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                        ariaLabel={""}
                      /> */}

                      <TableCell>{fund?.raw_data?.short_name}</TableCell>
                      <TableCell>
                        {formatCPFCNPJ(fund?.raw_data?.document_number)}
                      </TableCell>
                      <TableCell>
                        {fund?.raw_data?.active ? "Sim" : "Não"}
                      </TableCell>
                      <TableCell>{fund?.name}</TableCell>
                      <TableCell>
                        {canDeleteFunds && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenEditFundModal(true);
                              setFundDetails(fund);
                            }}
                          />
                        )}
                        {canDeleteFunds && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.stopPropagation();
                              setOpenDeleteFundModal(true);
                              setFundDetails(fund);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={funds?.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
        <Margin mb={50} />
      </Column>
      {/* <FilterModal onClose={() => setOpenModal(false)} open={openModal} />
      <DeleteProfileModal
        open={openDeleteProfileModal}
        onClose={() => setOpenDeleteProfileModal(!openDeleteProfileModal)}
      /> */}
      <NewFundModal
        onClose={() => setOpenNewFundModal(false)}
        open={openNewFundModal}
      />
      <DeleteFundModal
        onClose={() => setOpenDeleteFundModal(false)}
        open={openDeleteFundModal}
      />
      <EditFundModal
        onClose={() => setOpenEditFundModal(false)}
        open={openEditFundModal}
      />
    </Grid>
  );
};

export default Funds;
