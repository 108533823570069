import { IPaymentType } from "~/types";
import ApiService from "../api";

type IResponseGetPaymentTypes = {
  count: number;
  next: null;
  previous: null;
  results: IPaymentType[];
};

export type ISendNewPaymentTypeBody = {
  name: string;
  active?: boolean;
};

export const postPaymentTypeAPI = (data: ISendNewPaymentTypeBody) => {
  return ApiService.HttpPost({
    route: "payment/payment-request-type/",
    body: data,
    token: true,
  });
};

export const getPaymentTypeAPI = () => {
  return ApiService.HttpGet<IResponseGetPaymentTypes>({
    route: "payment/payment-request-type/",
    token: true,
  });
};

export const deletePaymentTypeAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `payment/payment-request-type/${id}/`,
    token: true,
  });
};

export const patchPaymentTypeAPI = (
  data: ISendNewPaymentTypeBody,
  id: number
) => {
  return ApiService.HttpPatch({
    route: `payment/payment-request-type/${id}/`,
    body: data,
    token: true,
  });
};
