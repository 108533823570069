import {
    Modal,
} from "prosperita-dumbo-react"
import useProfile from "~/hooks/useProfile";

type Imodal = {
    open: boolean,
    onClose?: () => void,
    onConfirm?: () => void
}

export const ModalAddConfirmedProfile = ({ open, onClose, onConfirm }: Imodal) => {

    const {
        setConfirmedModalAddProfile
    } = useProfile();

    return (
        <Modal
            onClose={onClose}
            open={open}
            height="250px"
            label={<h4 className="m-0 p-0">Deseja adicionar o novo usuário?</h4>}
            textLabel="O usuário irá receber um e-mail com suas credenciais iniciais e um link para configurar a senha."
            background="#f4f4f4"
            buttons={[
                {
                    label: "Cancelar",
                    kind: "ghost",
                    onClick: () => setConfirmedModalAddProfile(false)
                },
                {
                    label: "Confirmar e enviar",
                    icon: "",
                    kind: "primary",
                    onClick: onConfirm
                }
            ]}
        >
            <div className="mt-4 w-[100%] h-[2px] bg-[#c6c6c6]"></div>
        </Modal>
    )
}
