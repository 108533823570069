import { Column, Stack } from "@carbon/react";

import {
  InputSelect,
  InputFluid,
  FileUploader,
  Button,
  Radio,
  Breadcrumb,
} from "prosperita-dumbo-react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import ArrowIcon from "../../../assets/Send.png";
import {
  IBillettype,
  IPaymentType,
  ICompanyFund,
  IFund,
  IBeneficiary,
} from "~/types";
import useFund from "~/hooks/useFund";

import { permissions } from "~/services/permissions";
// import { postAttendLogAPI } from "~/services/api/attendLog";
import useUser from "~/hooks/useUser";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import Label from "~/components/Label";
import { postCheckBilletAPI } from "~/services/api/checkBillet";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import { getFundsAPI } from "~/services/api/fund";
import { getPaymentTypeAPI } from "~/services/api/paymentTypes";
import usePaymentType from "~/hooks/useTypesPayment";
import {
  getBeneficiariesAPI,
  getBeneficiaryFundFilteredPerFundAPI,
} from "~/services/api/beneficiaries";
import useBeneficiary from "~/hooks/useBeneficiary";
import { getCompanyFundAPI } from "~/services/api/companyFund";

// import { differenceInDays } from "date-fns";

interface PreviewFile extends File {
  preview: string;
}

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;

    background: #f4f4f4;
  }

  .form {
    background: #f4f4f4;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

const BilletContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 15px;
`;

const Value = styled.p`
  margin: 5px 0;
  font-size: 1.1rem;
  color: #333;
`;

const Barcode = styled.p`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.2rem;
  letter-spacing: 3px;
  color: #333;
`;

type Values = {
  message: string;
  search: string;
  client: string;
  fund: string;
  type_payment: string;
  barcode: string;
  bank_account: string;
  key: string;
  amount: string;
};

const initialValues: Values = {
  message: "",
  search: "",
  client: "",
  fund: "",
  type_payment: "",
  barcode: "",
  bank_account: "",
  key: "",
  amount: "",
};

const validationSchema = Yup.object({
  message: Yup.string().required("Descrição é obrigatória"),
  client: Yup.string(),
  fund: Yup.string().required("Fundo é obrigatório"),
  area_type: Yup.string().required("Tipo é obrigatório"),
});

const NewPaymentDetails = () => {
  const navigate = useNavigate();
  const { funds, fundsCompany, setFunds, setFundsCompany } = useFund();
  const { userProfile } = useUser();
  const { setPaymentTypes, paymentTypes } = usePaymentType();

  const {
    setBeneficiaries,
    Beneficiaries,
    setFundsBeneficiaryRender,
    // fundsBeneficiaryRender,
  } = useBeneficiary();

  const canCreateAttends = permissions.includes("can_create_attends");
  const { setLoading, loading } = useLoading();

  const [
    files,
    // setFiles
  ] = useState<PreviewFile[]>([]);
  const [renderInfoBillet, setRenderInfoBillet] =
    useState<IBillettype | null>();
  const [stateInfoBillet, setStateInfoBillet] = useState<boolean>(false);

  const {
    values,

    handleSubmit,
    //  isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);

      // fetchSendNewAttendPayment(sendNewAttendPayload);
    },
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const fundsOptions = useMemo(() => {
    return funds
      ?.filter((fund) => {
        return fund.raw_data.active === true;
      })
      ?.map((fund: IFund) => ({
        value: String(fund.id),
        label: `${formatCPFCNPJ(fund?.raw_data?.document_number).trim()} | ${fund?.raw_data?.short_name?.trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  const beneficiariesOptions = useMemo(() => {
    return Beneficiaries?.filter((beneficiary) => {
      return beneficiary.active;
    })
      ?.map((beneficiary: IBeneficiary) => ({
        value: String(beneficiary.id),
        label: `${formatCPFCNPJ(beneficiary?.document_number).trim()} | ${beneficiary?.name?.trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [Beneficiaries]);

  const typePaymentsOptions = useMemo(() => {
    return [
      { label: "Boleto", value: "billet" },
      { label: "PIX (Conta Bancária)", value: "pix_manual" },
      { label: "PIX (Chave)", value: "pix_key" },
      { label: "TED", value: "ted" },
    ];
  }, []);

  // function isLessThanTwoDays(date: Date): boolean {
  //   const today = new Date();
  //   const difference = differenceInDays(today, date);
  //   return difference <= 2;
  // }

  const fetchCompanyFunds = useCallback(async () => {
    try {
      if (userProfile?.company?.id) {
        const { data } = await getCompanyFundAPI(
          Number(userProfile?.company?.id)
        );
        setFundsCompany(data.results.reverse());
      }
      //   setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [userProfile, setFundsCompany]);

  const fetchBilletValidation = useCallback(async () => {
    setLoading(true);
    setRenderInfoBillet(null);

    try {
      const response = await postCheckBilletAPI({
        digitable_line: onlyNumbers(values.barcode),
      });
      setStateInfoBillet(true);
      const barcodeinfo = response.data as IBillettype;
      setRenderInfoBillet(barcodeinfo);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setStateInfoBillet(false);
      toast.error("Erro ao buscar dados do boleto!");
      setLoading(false);
    }
  }, [values.barcode, setLoading]);

  const fetchFundBeneficiary = useCallback(
    async (id: number) => {
      try {
        const { data } = await getBeneficiaryFundFilteredPerFundAPI(id);
        setFundsBeneficiaryRender(data.results.reverse());
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [setFundsBeneficiaryRender]
  );

  const companyFundsOptions = useMemo(() => {
    return fundsCompany
      ?.filter((fund) => {
        return fund.fund.raw_data.active === true;
      })
      ?.map((fundsCompany: ICompanyFund) => ({
        value: String(fundsCompany.fund.id),
        label: `${fundsCompany?.fund.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fundsCompany?.fund.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [fundsCompany]);

  const paymentTypesOptions = useMemo(() => {
    return paymentTypes
      ?.map((paymentType: IPaymentType) => ({
        value: String(paymentType.id),
        label: `${paymentType?.name}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [paymentTypes]);

  // const beneficiartFundsOptions = useMemo(() => {
  //   return (
  //     fundsBeneficiaryRender
  //       ?.filter(
  //         (beneficiaryFund: IBeneficiaryFund) =>
  //           beneficiaryFund?.beneficiary?.id
  //       ) // Filtra elementos inválidos
  //       .map((beneficiaryFund: IBeneficiaryFund) => ({
  //         value: String(beneficiaryFund.beneficiary?.id),
  //         label: `${beneficiaryFund.beneficiary?.name || ""} - ${formatCPFCNPJ(beneficiaryFund.beneficiary?.document_number || "")}`,
  //       }))
  //       .sort((a, b) =>
  //         a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
  //       ) || [] // Garante retorno de um array mesmo se fundsBeneficiaryRender for undefined
  //   );
  // }, [fundsBeneficiaryRender]);

  // const handleSelectTypeKeyPix = (
  //   selectedOption: SelectOption | null,
  //   actionMeta: { action: string }
  // ) => {
  //   if (selectedOption) {
  //     setFieldValue("key", selectedOption.value);
  //   } else if (actionMeta.action === "clear") {
  //     setFieldValue("key", "");
  //   }
  // };

  // const handleSelectBankAccount = (
  //   selectedOption: SelectOption | null,
  //   actionMeta: { action: string }
  // ) => {
  //   if (selectedOption) {
  //     setFieldValue("bank_account", selectedOption.value);
  //   } else if (actionMeta.action === "clear") {
  //     setFieldValue("bank_account", "");
  //   }
  // };

  const fetchPaymentTypes = useCallback(async () => {
    try {
      const { data } = await getPaymentTypeAPI();
      setPaymentTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setPaymentTypes]);

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchBeneficiaries = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesAPI();

      setBeneficiaries(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setBeneficiaries]);

  useEffect(() => {
    if (values.barcode.length >= 47 && values.barcode.length <= 50) {
      fetchBilletValidation();
    } else {
      setStateInfoBillet(false);
      setRenderInfoBillet(null);
    }
    if (/[^0-9. ]/.test(values.barcode)) {
      toast.error(
        "O código do boleto deve conter apenas números. Verifique os dados e tente novamente."
      );
    }
  }, [fetchBilletValidation, values]);

  useEffect(() => {
    if (values.fund !== "") {
      fetchFundBeneficiary(Number(values.fund));
    }
  }, [values.fund, fetchFundBeneficiary]);

  useEffect(() => {
    fetchFunds();
    fetchPaymentTypes();
    fetchBeneficiaries();
    fetchCompanyFunds();
  }, [fetchFunds, fetchPaymentTypes, fetchCompanyFunds, fetchBeneficiaries]);

  // const profilesFiltered = useMemo(() => {
  //   return profiles.filter((profile: IProfiles) => profile.type === "client");
  // }, [profiles]);

  // const sendFile = async (file: PreviewFile, dataId: string) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("sender", String(userProfile?.id || ""));
  //   formData.append("attend", String(dataId));
  //   formData.append("description", `${file?.name}`);

  //   await postAttendTaskFileAPI(formData); // Envia um único arquivo
  // };

  // const fetchSendNewAttendPayment = async (
  //   payload: ISendNewAttendPaymentBody
  // ) => {
  //   setLoading(true);

  //   try {
  //     const { data } = await postAttendPaymentAPI(payload);

  //     await postAttendLogAPI({
  //       attend: data.id,
  //       actual_area: areaID,
  //     });

  //     if (data && files.length > 0) {
  //       const filePromises = files.map((file: PreviewFile) =>
  //         sendFile(file, String(data.id))
  //       );

  //       await Promise.all(filePromises);

  //       setLoading(false);
  //       navigate("/app/attends/");
  //       resetForm();
  //       notifySuccess();

  //       return;
  //     }

  //     setLoading(false);
  //     navigate("/app/attends/");
  //     resetForm();
  //     notifySuccess();
  //   } catch (e) {
  //     console.error(e);
  //     setLoading(false);
  //     notifyError();
  //   }
  // };

  // const fetchProfiles = useCallback(async () => {
  //   setLoadingSearch(true);
  //   setSelectedIndex(null);

  //   try {
  //     const { data } = await getProfilesForAttendAPI(values.search);

  //     setProfiles(data.results);
  //     setLoadingSearch(false);
  //   } catch (err: unknown) {
  //     setLoadingSearch(false);
  //     console.error(err);
  //   }
  // }, [values.search, setProfiles]);

  return (
    <TicketFormContainer>
      {canCreateAttends ? (
        <form onSubmit={handleSubmit}>
          <Column span={16} className="container-fluid">
            <Padding y={24}>
              <Margin mb={-30} />
              <div
                style={{
                  display: "flex",
                  fontWeight: "200",
                  justifyContent: "flex-start",
                  margin: "0  0 30px 0",
                  padding: "0",
                }}
              ></div>
              {/* <Breadcrumb
                  crumbsOptions={[{ title: "dsdsdsd", value: "sdfdf" }]}
                /> */}
            </Padding>
            <div style={{ display: "flex", justifyContent: "start" }}>
              <Breadcrumb
                endHiddenIndex={1}
                startHiddenIndex={0}
                crumbsOptions={[
                  { crumb: "Painel", onClick: () => alert("dd") },
                  {
                    crumb: "Solicitação de pagamento",
                    onClick: () => alert("dd"),
                  },
                  { crumb: "Detalhes" },
                ]}
              />
            </div>
            <h1>#1 - Despesa contratual</h1>
            <Margin mb={-10} />

            <Column span={12} style={{ background: "#fff" }}>
              <div style={{ padding: "10px" }}>
                <Column span={12}>
                  <Padding y={12}></Padding>
                </Column>
                <Column span={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <span
                        style={{
                          color: "#525252",

                          fontFamily: "IBM Plex Sans",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "16px",
                          letterSpacing: "0.32px",
                        }}
                      >
                        Solicitante
                      </span>
                      <Margin mb={-5} />
                      <span>{userProfile?.name}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <div>
                        <Radio checked={true} label="Pagamento individual" />
                      </div>
                      <Margin ml={20} />
                      <div>
                        <Radio checked label="Pagamento em lote" />
                      </div>
                    </div>
                  </div>
                  <Stack gap={5}>
                    <Margin mb={5} />
                    {/* {canReadProfiles &&
                          userProfile?.type !== "client" &&
                          userProfile?.type !== "manager" && (
                            <Toggle
                              id="toggle-1"
                              labelText="Selecionar um cliente"
                              onToggle={handleToggle}
                              toggled={otherClient}
                              labelA=""
                              labelB=""
                            />
                          )} */}
                    {/* {otherClient && (
                          <div style={{ zIndex: 0 }}>
                            {" "}
                            <Search
                              id="search"
                              labelText="Pesquisar"
                              placeholder="Digite o CPF ou nome do usuário"
                              value={values.search}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Margin mb={30} />
                            {profilesFiltered.length > 0 && !loadingSearch ? (
                              <div
                                style={{
                                  height: "200px",
                                  overflow: "auto",
                                }}
                              >
                                <p>Clique para selecionar um usuário</p>
                                <Margin mb={10} />
                                {profilesFiltered?.map(
                                  (profile: IProfiles, index: number) => {
                                    return (
                                      <ContainerProfiles
                                        onClick={() => {
                                          if (selectedIndex === index) {
                                            setSelectedIndex(null);
                                          } else {
                                            setSelectedIndex(index);
                                            setFieldValue("client", profile.id);
                                          }
                                        }}
                                        key={String(index)}
                                        style={{
                                          background:
                                            selectedIndex === index
                                              ? "#6ea0fc"
                                              : "",
                                        }}
                                      >
                                        <Margin ml={5} />
                                        <div
                                          style={{
                                            background:
                                              selectedIndex === index
                                                ? "#85b0ff"
                                                : "#b8b8b8",
                                            borderRadius: "50%",
                                            width: "50px",
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              color:
                                                selectedIndex === index
                                                  ? "#0f62fe"
                                                  : "",
                                            }}
                                          >
                                            {getFirstLetters(profile.name)}
                                          </h4>
                                        </div>
                                        <Margin ml={15} />
                                        <div>
                                          <p style={{ fontWeight: "bold" }}>
                                            {profile.name}
                                          </p>
                                          <p>
                                            {formatCPFCNPJ(
                                              profile.document_number
                                            )}
                                          </p>
                                        </div>
                                      </ContainerProfiles>
                                    );
                                  }
                                )}
                              </div>
                            ) : loadingSearch ? (
                              <div
                                style={{
                                  height: "200px",
                                  overflow: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loading withOverlay={false} />
                              </div>
                            ) : !loadingSearch && values.search !== "" ? (
                              <div
                                style={{
                                  height: "200px",
                                  overflow: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h4>Nenhum resultado encontrado</h4>
                              </div>
                            ) : (
                              <div
                                style={{
                                  height: "20px",
                                  overflow: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              ></div>
                            )}
                          </div>
                        )} */}
                    <div style={{ background: "#F4F4F4", borderRadius: "8px" }}>
                      <div className="d-flex">
                        <InputSelect
                          noRadius
                          kind="fluid"
                          size="medium"
                          label="Fundo de investimento"
                          options={
                            userProfile?.type?.includes("client") ||
                            userProfile?.type?.includes("manager")
                              ? companyFundsOptions
                              : fundsOptions
                          }
                          // onChange={handleSelectFund}
                          placeholder="Pesquisar ou selecionar fundo..."
                        />
                        <InputSelect
                          noRadius
                          kind="fluid"
                          size="medium"
                          label="Beneficiário"
                          options={beneficiariesOptions}
                          // onChange={(selectedOption, actionMeta) =>
                          //   handleSelectBankAccount(
                          //     selectedOption,
                          //     actionMeta
                          //   )
                          // }
                          // // isDisabled={values.fund === ""}
                          placeholder="Pesquisar ou selecionar beneficiário..."
                        />
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr",
                          gridGap: "0px",
                          alignItems: "end",
                          justifyContent: "center",
                        }}
                      >
                        <InputSelect
                          noRadius
                          kind="fluid"
                          size="medium"
                          label="Tipo de despesa"
                          options={paymentTypesOptions}
                          // onChange={(selectedOption, actionMeta) =>
                          //   handleSelectBankAccount(selectedOption, actionMeta)
                          // }
                          placeholder="Pesquisar ou selecionar o tipo de despesa..."
                        />

                        <InputSelect
                          kind="fluid"
                          size="medium"
                          noRadius
                          label="Forma de pagamento"
                          options={typePaymentsOptions}
                          // onChange={(selectedOption, actionMeta) =>
                          //   handleSelectTypePayment(selectedOption, actionMeta)
                          // }
                          placeholder="Pesquisar ou selecionar a forma de pagamento..."
                        />
                        <InputSelect
                          kind="fluid"
                          size="medium"
                          noRadius
                          label="Chave / Conta"
                          options={typePaymentsOptions}
                          // onChange={(selectedOption, actionMeta) =>
                          //   handleSelectTypePayment(selectedOption, actionMeta)
                          // }
                          placeholder="Pesquisar ou selecionar a forma de pagamento..."
                        />
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr",
                          gridGap: "0px",
                          alignItems: "end",
                          justifyContent: "center",
                        }}
                      >
                        <InputFluid
                          noRadius
                          label="Boleto"
                          // onChange={(selectedOption, actionMeta) =>
                          //   handleSelectBankAccount(selectedOption, actionMeta)
                          // }
                          placeholder="Insira a linha digitável..."
                        />
                        <InputFluid
                          noRadius
                          label="Valor total"
                          // onChange={(selectedOption, actionMeta) =>
                          //   handleSelectBankAccount(selectedOption, actionMeta)
                          // }
                          placeholder="R$"
                        />
                      </div>
                      <div>
                        <InputFluid
                          type="textarea"
                          label="Justificativa"
                          placeholder="Digite sua justificativa"
                          maxLength={500}
                          rows={4}
                        />
                      </div>
                    </div>

                    {!loading &&
                    stateInfoBillet &&
                    values.barcode.length >= 47 ? (
                      <BilletContainer>
                        <Section>
                          <Label text="Banco:"></Label>
                          <Value>
                            {renderInfoBillet?.bank_code} -{" "}
                            {renderInfoBillet?.beneficiary_name}
                          </Value>
                        </Section>

                        <Section>
                          <Label text="Beneficiário:"></Label>
                          <Value>
                            {renderInfoBillet?.payer_name} (
                            {renderInfoBillet?.payer_document_number})
                          </Value>
                        </Section>
                        <Section>
                          <Label text="Vencimento:"></Label>
                          <Value>
                            {" "}
                            {new Date(
                              String(renderInfoBillet?.due_date)
                            ).toLocaleDateString()}
                          </Value>
                        </Section>

                        <Section>
                          <Label text="Valor:"></Label>
                          <Value>R$ {renderInfoBillet?.amount}</Value>
                        </Section>

                        <Section>
                          <Label text="Linha Digitável:"></Label>
                          <Value>{renderInfoBillet?.digitable_line}</Value>
                        </Section>

                        <Section>
                          <Label text="Código de Barras:"></Label>
                          <Barcode>{renderInfoBillet?.barcode}</Barcode>
                        </Section>
                      </BilletContainer>
                    ) : (
                      !stateInfoBillet &&
                      !loading &&
                      values.barcode.length >= 47 && (
                        <p
                          style={{
                            textAlign: "center",
                            color: "red",
                            margin: "0",
                          }}
                        >
                          O boleto informado é inválido. Por favor, verifique os
                          dados e tente novamente.
                        </p>
                      )
                    )}
                    {/* <MoneyInput
                      name="amount"
                      label="Valor (R$)"
                      id="amount"
                      onChange={handleChange}
                      value={
                        renderInfoBillet ? String(renderInfoBillet.amount) : ""
                      }
                      placeholder={"Digite o Valor"}
                    ></MoneyInput> */}

                    <FileUploader
                      maxlenghtindividual={3}
                      size="medium"
                      type="drag and top"
                      headers={{
                        label: "Anexar arquivos",
                        TextLabel:
                          "O tamanho máximo do arquivo é 50mb. Os tipos de arquivo suportados são .pdf .jpeg .png .csv",
                      }}
                    >
                      Arraste e solte aqui ou clique para importar
                    </FileUploader>

                    {/* <Label text="Selecione seus documentos para enviar" />
                    <div
                      {...getRootProps()}
                      style={{
                        border: "2px dashed #cccccc",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <input {...getInputProps()} />

                      <p>
                        Arraste e solte alguns arquivos aqui, ou clique para
                        selecionar os arquivos
                      </p>
                    </div> */}
                  </Stack>
                </Column>

                {/* <Column span={12}>
                  <Margin mb={64} />
                  <Grid>
                    <Column span={3}>
                      <Button
                        kind="tertiary"
                        className="action-button"
                        onClick={() => navigate("/app/attends/")}
                      >
                        Cancelar
                      </Button>
                    </Column>
                    <Column span={3}>
                      <Button
                        kind="primary"
                        className="action-button"
                        type="submit"
                        // disabled={
                        //   !isValid ||
                        //   loading ||
                        //   (otherClient && values.client === "")
                        // }
                        onClick={() => console.log(values)}
                      >
                        Enviar
                      </Button>
                    </Column>
                  </Grid>
                </Column> */}
                <div
                  style={{
                    justifyContent: "end",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Button
                    kind="primary"
                    size="large"
                    label="Prosseguir"
                    icon={ArrowIcon}
                    onClick={() => navigate("/app/payments/new-details")}
                  />
                </div>

                <Column span={6} />
              </div>

              <Margin mb={64} />
            </Column>
            <Margin mb={64} />
          </Column>
        </form>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para criar atendimentos</h4>
        </div>
      )}
    </TicketFormContainer>
  );
};

export default NewPaymentDetails;
