import { Grid, Column } from "@carbon/react";

import renewIcon from "~/assets/Renew.png";
import editIcon from "~/assets/editicon.png";
import addIcon from "~/assets/iconadd.png";
import DetailsIcon from "~/assets/View.png";
import DeleteIcon from "~/assets/Trash-can.png";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { getAreasAPI } from "~/services/api/areas";
import useArea from "~/hooks/useArea";
import NewAreaContentModal from "./NewAreaContentModal";
import DeleteAreaModal from "./DeleteAreaModal";
import { useNavigate } from "react-router-dom";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";

import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import EditAreaModal from "./EditAreaModal";
import { TableBasic, Tag, Tooltip } from "prosperita-dumbo-react";

const Areas = () => {
  const { setLoading } = useLoading();

  const {
    setAreas,
    areas,
    setOpenDeleteAreaModal,
    openDeleteAreaModal,
    setAreaDetails,
    setAttendAreaProfiles,
    setOpenEditAreaModal,
    openEditAreaModal,
  } = useArea();

  const { setAreaTypes } = useAreaType();

  const navigate = useNavigate();

  const [openNewAreaModal, setOpenNewAreaModal] = useState<boolean>(false);

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const dataAttendTypes = useMemo(() => {
    return areas?.map((area, index) => {
      return {
        Nome: <div className="flex flex-col align-center">{area.name}</div>,

        Status: area.active ? (
          <div className="w-[4px] m-0 p-0">
            <Tag type="green" position="center">
              <p className="text-[14px] m-0 p-0">Ativa</p>
            </Tag>
          </div>
        ) : area?.active === false ? (
          <div className="w-[4px] m-0 p-0">
            <Tag type="magenta" position="center">
              <p className="text-[14px] m-0 p-0">Inativa</p>
            </Tag>
          </div>
        ) : (
          ""
        ),
        edit: (
          <div style={{ display: "flex", gap: "20px" }}>
            <Tooltip align="top" label="Visualizar detalhes" size="large">
              <div key={index} className="m-0 p-0">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/app/areas/${area.id}/`);
                  }}
                >
                  <img
                    style={{ objectFit: "contain" }}
                    alt="View"
                    src={DetailsIcon}
                  />
                </button>
              </div>
            </Tooltip>
            <Tooltip align="top" label="Editar" size="large">
              <div key={index} className="m-0 p-0">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenEditAreaModal(true);
                    setAreaDetails(area);
                  }}
                >
                  <img
                    style={{ objectFit: "contain" }}
                    alt="Editar"
                    src={editIcon}
                  />
                </button>
              </div>
            </Tooltip>
            <Tooltip align="top" label="Excluir" size="large">
              <div key={index} className="m-0 p-0">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDeleteAreaModal(true);
                    setAreaDetails(area);
                  }}
                >
                  <img
                    style={{ objectFit: "contain" }}
                    alt="Excluir"
                    src={DeleteIcon}
                  />
                </button>
              </div>
            </Tooltip>
          </div>
        ),
      };
    });
  }, []);

  const tableHeadersProfile: string[] = ["Nome", "Status", ""];

  const fetchAreas = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setAreas, setLoading]);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI();
      setAttendAreaProfiles(data.results.reverse());
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  useEffect(() => {
    fetchAreas();
    fetchAttendAreaProfiles();
    fetchAreaTypes();
  }, [fetchAreas, fetchAreaTypes, fetchAttendAreaProfiles]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Áreas</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableBasic
          search
          buttons={[
            {
              kind: "ghost",
              label: "",
              dimension: "37",
              width: "37px",
              onClick: () => fetchAreas(),
              position: "right",
              icon: renewIcon,
            },

            {
              kind: "primary",
              label: "Nova àrea",
              dimension: "",
              onClick: () => setOpenNewAreaModal(true),
              position: "right",
              icon: addIcon,
            },
          ]}
          pageSizes={[10, 5, 20, 30, 40, 50]}
          rowData={dataAttendTypes}
          columnTitles={tableHeadersProfile}
        />
      </Column>
      <NewAreaContentModal
        onClose={() => setOpenNewAreaModal(false)}
        open={openNewAreaModal}
      />
      <EditAreaModal
        onClose={() => setOpenEditAreaModal(false)}
        open={openEditAreaModal}
      />
      <DeleteAreaModal
        onClose={() => setOpenDeleteAreaModal(false)}
        open={openDeleteAreaModal}
      />
    </Grid>
  );
};

export default Areas;
