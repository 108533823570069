import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import {
  TableExpandable,
  InputFluid,
  Dropdown,
  Tab,
  TabPane,
  Breadcrumb,
  Tag,
  Button,
  Checkbox,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import exporticon from "~/assets/Export.png";
import filtericon from "~/assets/Filter.png";
import moneyicon from "~/assets/Money.png";
import renewicon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import { IAttend, IAttendPayment, IFund } from "~/types";
// import { useFormik } from "formik";
// import FiltersModal from "./FiltersModal";
// import { format } from "date-fns";
import styled from "styled-components";
import Badge from "~/components/Badge";
// import { permissions } from "~/services/permissions";
import usePayment from "~/hooks/usePayment";
import { getAttendPaymentsAPI, getPaymentAPI } from "~/services/api/payment";
// import { format } from "date-fns";
import useFund from "~/hooks/useFund";
import { formatCPFCNPJ } from "~/services/utils";
import { getFundsAPI } from "~/services/api/fund";
import { getBeneficiariesAPI } from "~/services/api/beneficiaries";
import useBeneficiary from "~/hooks/useBeneficiary";
import usePaymentType from "~/hooks/useTypesPayment";
import { getPaymentTypeAPI } from "~/services/api/paymentTypes";
import useUser from "~/hooks/useUser";
// import useProfile from "~/hooks/useProfile";
// import useUser from "~/hooks/useUser";

// const renderStatus: { [key: string]: React.ReactNode } = {
//   opened: <Badge label="Em aberto" color="#e3e3e3" textColor="#696868" />,
//   created: (
//     <Badge color="#bacbff" textColor="#1906ec" label="Aguar. Pagamento" />
//   ),
//   in_progress: (
//     <Badge color="#ffe9c9" textColor="#ff9900" label="Em andamento" />
//   ),
//   done: <Badge color="#CCEFDF" textColor="#00B360" label="Finalizado" />,
//   return_to_client: (
//     <Badge color="#bacbff" textColor="#1906ec" label="Retor. solicitante" />
//   ),
//   canceled: <Badge label="Cancelado" textColor="#ff0000" color="#f9c6c6" />,
// };

const Container = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  width: 100%;
`;
const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;
const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;
const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const Payments = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [activeFilter, setActiveFilter] = useState<boolean>(false);
  const { payments, setPayments, setpayment } = usePayment();
  const [attendsPayment, setAttendsPayment] = useState<IAttend[]>();
  const [stateKeytab, setstateKeytab] = useState<number>(0);
  const { setFunds, funds } = useFund();

  const { Beneficiaries, setBeneficiaries } = useBeneficiary();

  const { paymentTypes, setPaymentTypes } = usePaymentType();
  const { userProfile } = useUser();
  // const [openModal, setOpenModal] = useState<boolean>(false);
  //const canReadAttends = permissions.includes("can_read_attends");
  // const canDeleteAttends = permissions.includes("can_delete_attends")
  // const canEditAttends = permissions.includes("can_edit_attends")
  //const canCreatAttends = permissions.includes("can_create_attends");
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [isParentIndeterminate, setIsParentIndeterminate] = useState(false);
  const [checkedIndexes, setCheckedIndexes] = useState<boolean[]>(
    Array(payments.length).fill(false)
  );

  const handleCheckboxChange = (rowIndex: number, payment: IAttendPayment) => {
    setpayment(payment);
    setCheckedIndexes((prev) => {
      const updatedChecks = [...prev];
      updatedChecks[rowIndex] = !updatedChecks[rowIndex];

      const totalChecked = updatedChecks.filter(Boolean).length;
      const allChecked = totalChecked === payments.length;
      const someChecked = totalChecked > 0;

      setIsParentChecked(someChecked);
      setIsParentIndeterminate(!allChecked && someChecked);

      return updatedChecks;
    });
  };
  const filterCheckds = checkedIndexes.filter((items) => items === true);
  const someChecked = checkedIndexes.some((items) => items === true);

  const handleParentCheckboxChange = () => {
    const newState = !isParentChecked;
    setCheckedIndexes(Array(payments.length).fill(newState));
    setIsParentChecked(newState);
    setIsParentIndeterminate(false);
  };
  const titlesColums = [
    <Checkbox
      key={1}
      checked={isParentChecked}
      indeterminate={isParentIndeterminate}
      onChange={handleParentCheckboxChange}
    />,
    "ID",
    "Data",
    "Fundo",
    "Beneficiário",
    "Despesa",
    "Valor Total",
    "Pagamento",
    "",
    "Status",
    "",
  ];

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const filteredFunds = funds.filter((fund) => fund.raw_data.active === true);

  const fundsOptions = useMemo(() => {
    return filteredFunds
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [filteredFunds]);

  // interface StatusCounts {
  //   created?: number;
  //   done?: number;
  //   in_progress?: number;
  //   opened?: number;
  //   canceled?: number;
  //   return_to_client?: number;
  //   [key: string]: number | undefined;
  // }

  // const totalAttendsPayments = useMemo<StatusCounts>(() => {
  //   const statusCounts: StatusCounts = payments?.reduce((acc, payment) => {
  //     const status = payment?.status?.slug;
  //     if (status) {
  //       acc[status] = (acc[status] || 0) + 1;
  //     }
  //     return acc;
  //   }, {} as StatusCounts);

  //   return statusCounts || {};
  // }, [payments]);

  // const renderTiles: { label: string; total: number }[] = [
  //   { label: "Criados", total: 0 },
  //   { label: "Em aberto ", total: totalAttendsPayments?.opened ?? 0 },
  //   { label: "Em andamento", total: totalAttendsPayments?.in_progress ?? 0 },
  //   {
  //     label: "Aguardando Pagamento",
  //     total: 1,
  //   },
  //   { label: "Finalizados", total: totalAttendsPayments?.done ?? 0 },
  //   { label: "Cancelados", total: totalAttendsPayments?.canceled ?? 0 },
  // ];

  // const filterAttends = () => {
  //   const filtered = attends.filter((profile) =>
  //     profile.subject.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredAttends(filtered);
  // };
  const fetchBeneficiary = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiariesAPI();
      setBeneficiaries(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setBeneficiaries]);

  const filterPaymentType: Array<{
    value: string;
    label: string;
  }> = useMemo(() => {
    return paymentTypes.map((label) => ({
      value: String(label.id),
      label: label.name,
    }));
  }, [paymentTypes]);

  const filterBeneficiaries: Array<{
    value: string;
    label: string;
  }> = useMemo(() => {
    return Beneficiaries.map((label) => ({
      value: String(label.id),
      label: String(label.name),
    }));
  }, [Beneficiaries]);

  const fetchPaymentTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getPaymentTypeAPI();
      setPaymentTypes(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      console.error(err);
    }
  }, [setPaymentTypes, setLoading, filterPaymentType]);

  const fetchAttendsPayments = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendPaymentsAPI();
      setAttendsPayment(data.results.reverse().sort((a, b) => b.id - a.id));
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const fetchPayments = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getPaymentAPI();
      setPayments(data.results.reverse().sort((a, b) => b.id - a.id));
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const listRender: any = payments.flatMap((payment, index) =>
    payment.bank_account?.map((item) => {
      const attendPayment = attendsPayment?.find(
        (attend) => Number(payment.attend.id) === attend.id
      );
      const date = new Date(payment.created_on);
      const formattedDate = new Intl.DateTimeFormat("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(date);
      const formattedTime = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(date);
      return {
        ID: `#${payment.id}`,
        conta: item.account_number,
        digito: item.account_number_digit,
        "Valor total": `${new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(payment.amount)).padEnd(Number(payment.amount).toString().length + 6, " ")}`,
        Despesa: payment.type.name,
        Fundo: attendPayment?.fund.raw_data.short_name || "N/A",
        "Fundo:": `${attendPayment?.fund.raw_data.short_name || "N/A"} | ${attendPayment?.fund.raw_data.document_number || "N/A"}`,
        Data: `${formattedDate} - ${formattedTime}`,
        Pagamento: (
          <p style={{ textAlign: "center" }}>
            {payment.method?.toLocaleUpperCase() || "Desconhecido"}
          </p>
        ),
        Status: (
          <Tag size="medium">
            <p style={{ fontSize: "14px", margin: "0", padding: "0" }}>
              Em Aprovação
            </p>
          </Tag>
        ),
        Beneficiário: (
          <>
            <p
              style={{
                margin: "0",
                padding: "0",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Banvox
            </p>
            {payment.beneficiary.name}
          </>
        ),
        "Beneficiário:": `${payment.bank_account?.flatMap((items) => items.account_number)} | ${payment.beneficiary.name}`,
        "Informaçoes da Conta:": `${payment.bank_account?.flatMap((account) => ` AG.:${account.agency} CC.:${account.account_number}`)}`,
        checkbox: (
          <Checkbox
            key={index}
            checked={checkedIndexes[index]}
            onClick={() => {
              handleCheckboxChange(index, payment);
            }}
          />
        ),
      };
    })
  );

  useEffect(() => {
    setCheckedIndexes(Array(payments.length).fill(false));
    fetchAttendsPayments();
    fetchPayments();
    fetchFunds();
    fetchBeneficiary();
    fetchPaymentTypes();
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div
        style={{
          background: "#F4F4F4",
          height: "170px",
          margin: "0",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              margin: "50px 0 0 50px ",
              width: "50%",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
              textAlign: "start",
            }}
          >
            <Breadcrumb
              crumbsOptions={[
                { crumb: "Painel", onClick: () => navigate("/app") },
                { crumb: "Pagamentos e despesas" },
              ]}
              endHiddenIndex={5}
              startHiddenIndex={5}
            />
          </div>
          <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
            Pagamentos e despesas
          </h2>
        </div>
      </div>
      <div style={{ width: "96%", margin: "0 auto" }}>
        {userProfile?.type.includes("tool_manager") ? (
          <>
            {payments.length > 0 ? (
              <>
                <Tab width="100px">
                  <TabPane
                    label="Pagamento individual"
                    isActive={stateKeytab === 0}
                    onClick={() => setstateKeytab(0)}
                    bordercolor="rgb(10, 74, 135)"
                    width="200px"
                  >
                    <Margin mt={200} />
                    <div style={{ width: "99%", margin: "20px auto" }}>
                      {activeFilter && (
                        <div
                          style={{
                            background: "#F4F4F4",
                            height: "auto",
                            margin: "0px 0",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <section style={{ margin: "30px" }}>
                                <h2>Filtros</h2>
                                <Margin mt={30}></Margin>
                                <h4 style={{ fontWeight: "600" }}>
                                  Configurações gerais
                                </h4>
                              </section>
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "97%",
                                  justifyContent: "center",
                                  margin: "0 auto",
                                  padding: "0",
                                }}
                              >
                                <div style={{ width: "550px" }}>
                                  <Dropdown
                                    kind="fluid"
                                    options={fundsOptions}
                                    size="large"
                                    noRadius
                                    label="Fundo"
                                    placeholder="Selecione"
                                  />
                                </div>
                                <div style={{ width: "400px" }}>
                                  <Dropdown
                                    kind="fluid"
                                    options={filterBeneficiaries}
                                    size="large"
                                    noRadius
                                    label="Beneficiario"
                                    placeholder="Selecione"
                                  />
                                </div>
                                <div style={{ width: "400px" }}>
                                  <Dropdown
                                    kind="fluid"
                                    options={filterPaymentType}
                                    size="large"
                                    noRadius
                                    label="Tipo de Despesa"
                                    placeholder="Selecione"
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "96%",
                                  justifyContent: "center",
                                  margin: "50px auto",
                                }}
                              >
                                <Dropdown
                                  kind="fluid"
                                  options={[
                                    { label: "TED", value: "1" },
                                    { label: "BOLETO", value: "2" },
                                    { label: "PIX", value: "3" },
                                  ]}
                                  size="large"
                                  noRadius
                                  label="Tipo de pagamento"
                                  placeholder="Selecione"
                                />

                                <InputFluid
                                  noRadius
                                  label="Valor inicial"
                                  placeholder="R$"
                                  type="text"
                                />

                                <InputFluid
                                  noRadius
                                  label="Valor Final"
                                  placeholder="R$"
                                  type="text"
                                />

                                <InputFluid
                                  noRadius
                                  label="Data Inicial"
                                  placeholder="00/00/0000"
                                  type="date"
                                />
                                <InputFluid
                                  noRadius
                                  label="Data Final"
                                  placeholder="00/00/0000"
                                  type="date"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "0em 0 2em 0",
                              margin: "0",
                              display: "flex",
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              <h4 style={{ margin: "20px", fontWeight: "600" }}>
                                Status
                              </h4>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                    margin: "0px 20px",
                                  }}
                                >
                                  <Badge
                                    label="Aprovada"
                                    textColor="#161616"
                                    color="#E0E0E0"
                                  />
                                  <Badge
                                    label="aprovação"
                                    textColor="#161616"
                                    color="#E0E0E0"
                                  />
                                  <Badge
                                    label="Pendente"
                                    textColor="#161616"
                                    color="#E0E0E0"
                                  />
                                  <Badge
                                    label="Rejeitada"
                                    textColor="#161616"
                                    color="#E0E0E0"
                                  />
                                </div>
                                <div style={{ marginRight: "25px" }}>
                                  <Button
                                    kind="tertiary"
                                    size="medium"
                                    label="Aplicar filtros"
                                    icon={filtericon}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <TableExpandable
                        search
                        actions={[
                          {
                            text: <p>Cancelar solicitação</p>,
                            onClick: () => "",
                          },
                          { text: <p>Editar</p>, onClick: () => "" },
                        ]}
                        columnTitles={titlesColums}
                        headerAction={{
                          active: someChecked,
                          count: filterCheckds.length,
                        }}
                        columsKeys={[
                          "checkbox",
                          "ID",
                          "Data",
                          "Fundo",
                          "Beneficiário",
                          "Despesa",
                          "Valor total",
                          "Pagamento",
                          "",
                          "Status",
                          "",
                        ]}
                        expandedRowData={[
                          "Fundo:",
                          "Beneficiário:",
                          "Informaçoes da Conta:",
                        ]}
                        pageSizes={[7, 5, 2, 1, 30]}
                        width="100%"
                        rowData={listRender}
                        buttons={[
                          {
                            label: "",
                            kind: "ghost",
                            icon: renewicon,
                            position: "right",
                            onClick: () => fetchAttendsPayments(),
                          },
                          {
                            label: "",
                            kind: "ghost",
                            icon: exporticon,
                            position: "right",
                          },
                          {
                            label: "",
                            kind: "ghost",
                            icon: filtericon,
                            position: "right",
                            onClick: () => setActiveFilter((prev) => !prev),
                          },
                          {
                            label: "Nova solicitação de pagamento",
                            kind: "primary",
                            icon: moneyicon,
                            position: "right",
                            onClick: () => navigate("/app/payments/new"),
                          },
                        ]}
                      />
                      <Margin mt={100} />
                    </div>
                  </TabPane>
                  <TabPane
                    label="Pagamentos em lote"
                    isActive={stateKeytab === 1}
                    onClick={() => setstateKeytab(1)}
                    bordercolor="rgb(10, 74, 135)"
                    disabled
                    width="200px"
                  ></TabPane>
                </Tab>
              </>
            ) : (
              <div>
                <Container>
                  <div
                    style={{
                      width: "320px",
                      marginTop: "40px",
                    }}
                  >
                    <Button
                      kind="primary"
                      size="medium"
                      label={"Nova solicitação de pagamento"}
                      onClick={() => navigate("/app/payments/new")}
                      icon={moneyicon}
                    />
                  </div>
                </Container>
                <div style={{ width: "96%", margin: "0 auto" }}>
                  <TagLup src={lupaIcon} />
                  <Text>Nenhuma solicitação emitida até o momento</Text>
                  <Subtext>
                    Para realizar uma solicitação de pagamento, clique no botão
                    “Nova solicitação de pagamento” localizado no canto superior
                    direito.
                  </Subtext>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div>
              <Container>
                <div
                  style={{
                    width: "320px",
                    marginTop: "40px",
                  }}
                ></div>
              </Container>
              <div style={{ width: "96%", margin: "0 auto" }}>
                <TagLup src={lockIcon} />
                <Text>Acesso restrito</Text>
                <Subtext>
                  Você não tem permissão para ver o painel de Pagamentos e
                  despesas, contate o administrador do sistema para mais
                  informações.
                </Subtext>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Payments;
