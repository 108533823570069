import constate from "constate";
import { useState } from "react";
import { IAttendPayment } from "~/types";


type ITypePayment = {
  id: number,
  deleted: null,
  deleted_by_cascade: boolean,
  name: string,
  active: true,
  created_on: string,
  updated_on: string
};

const [PaymentProvider, usePayment] = constate(() => {
  const [typePayments, setTypePayments] = useState<ITypePayment[]>();
  const [payments, setPayments] = useState<IAttendPayment[]>([]);
  const [paymentsDetails, setPaymentsDetails] = useState<IAttendPayment>();
  const [payment, setpayment] = useState<IAttendPayment>()

  return {
    typePayments,
    setTypePayments,
    payments,
    setPayments,
    paymentsDetails,
    setPaymentsDetails,
    setpayment,
    payment
  };
});

export { PaymentProvider };

export default usePayment;
