import constate from "constate";
import { useState } from "react";
import { IStatus } from "~/types";

const [StatusProvider, useStatus] = constate(() => {
  const [status, setStatus] = useState<IStatus[]>([]);

  const [openDeleteAreaModal, setOpenDeleteAreaModal] =
    useState<boolean>(false);

  const [detailsStatus, setDetailsStatus] = useState<IStatus>();

  const [openEditStatusModal, setOpenEditStatusModal] =
    useState<boolean>(false);

  const [openNewStatusModal, setOpenNewStatusModal] = useState<boolean>(false);

  return {
    status,
    setStatus,
    setOpenDeleteAreaModal,
    openDeleteAreaModal,
    openNewStatusModal,
    setOpenNewStatusModal,
    detailsStatus,
    setDetailsStatus,

    openEditStatusModal,
    setOpenEditStatusModal,
  };
});

export { StatusProvider };

export default useStatus;
