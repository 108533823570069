import constate from "constate";
import { useState } from "react";
import { IActualUser, IProfiles } from "~/types";

const [UserProvider, useUser] = constate(() => {

  const [user, setUser] = useState<IActualUser>();

  const [typeUserProfile, setTypeUserProfile] =
    useState<string>("tool_manager");

  const [userProfile, setUserProfile] = useState<IProfiles>();

  const [pass, setPass] = useState<string>("");

  const [openChangePasswordModal, setOpenChangePasswordModal] =
    useState<boolean>(false);

  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

  const [updateChangePasswordModal,
    setUpdateChangePasswordModal] = useState<boolean>(false)

  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen]
    = useState<boolean>(false);

  const [isConfirmSendPasswordModalOpen, setIsConfirmSendPasswordModalOpen] =
    useState<boolean>(false);
    
  const [verifiedEmail, setverifiedEmail] = useState<string>("")


  return {
    user,
    verifiedEmail,
    setverifiedEmail,
    setIsConfirmSendPasswordModalOpen,
    isConfirmSendPasswordModalOpen,
    setIsForgotPasswordModalOpen,
    isForgotPasswordModalOpen,
    setUpdateChangePasswordModal,
    updateChangePasswordModal,
    setUser,
    userProfile,
    setUserProfile,
    setTypeUserProfile,
    typeUserProfile,
    openChangePasswordModal,
    setOpenChangePasswordModal,
    pass,
    setPass,
    isFirstLogin,
    setIsFirstLogin,
  };
});

export { UserProvider };

export default useUser;
