import { Modal, TextInput, Toggle } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useMemo } from "react";
import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";

import useStatus from "~/hooks/useStatus";
import {
  getStatusAPI,
  ISendUpdateStatus,
  postStatusAPI,
} from "~/services/api/status";

type Values = {
  name: string;
  color: string | undefined;
  active: boolean | undefined;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const NewStatusContentModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setOpenNewStatusModal, setStatus } = useStatus();

  const notifySuccess = () => toast.success("Status criado com sucesso!");
  const notifyError = () =>
    toast.error("Não foi possível criar o Status. Tente novamente mais tarde.");

  const initialValues: Values = {
    name: "",
    color: "",
    active: true,
  };

  const { handleSubmit, values, handleBlur, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const handleToggle = () => {
    setFieldValue("active", !values.active);
  };

  const payload: ISendUpdateStatus = useMemo(() => {
    return {
      name: values.name ?? "",
      color: values.color ?? "",
      active: values.active,
    };
  }, [values]);

  const fetchStatus = useCallback(async () => {
    try {
      const { data } = await getStatusAPI();
      setStatus(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setStatus]);

  const fetchUpdateStatus = async (payload: ISendUpdateStatus) => {
    setLoading(true);
    try {
      await postStatusAPI(payload);
      setOpenNewStatusModal(false);

      await fetchStatus();

      setLoading(false);
      notifySuccess();
    } catch (error) {
      console.error("Erro ao excluir", error);
      setLoading(false);
      notifyError();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 6 }}
        modalHeading={`Novo Status`}
        size="sm"
        open={open}
        primaryButtonDisabled={values.name === ""}
        onRequestClose={() => {
          onClose();
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchUpdateStatus(payload);
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Nome"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={20} />

        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <label htmlFor="colorPicker" className="bx--label">
            Cor do Badge
          </label>
          <input
            type="color"
            id="color"
            value={values.color}
            onChange={handleChange}
            style={{
              height: "3rem",
              width: "3rem",
              border: "none",
              cursor: "pointer",
            }}
          />
          <TextInput
            id="color"
            labelText="Cor selecionada (hex):"
            value={values.color}
            onChange={handleChange}
            placeholder="#000000"
          />
        </div>

        <Margin mb={24} />

        <Toggle
          id="active"
          labelText="Status"
          onToggle={handleToggle}
          toggled={values.active}
          labelA="Inativo"
          labelB="Ativo"
        />
        <Margin mb={24} />
      </Modal>
    </form>
  );
};

export default NewStatusContentModal;
