import {
  Grid,
  Column,
  Form,
  Heading,
  PasswordInput,
  Stack,
  Button,
  Checkbox,
  InlineNotification,
} from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import BanvoxLogo from "~/assets/banvox.png";
import BgLogin from "~/assets/bgLogin.png";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getRefreshTokenAPI, getTokenAPI } from "~/services/api/auth";
import { useCallback, useEffect, useState } from "react";
import AppStorage from "~/services/storage";
import useLoading from "~/hooks/useLoading";
import useUser from "~/hooks/useUser";
import MaskedTextInput from "~/components/InputMask";
import { onlyNumbers } from "~/services/utils";
import { ForgotPasswordModal } from "./ForgotPasswordModal";

const LoginContainer = styled.div`
  min-height: 100vh;

  .right-image {
    width: 100%;
    height: 100vh;

    background-image: url(${BgLogin});

    background-repeat: no-repeat;
    background-position-x: end;
    background-position-y: center;
  }

  .form-btn {
    width: 100%;
    max-width: 100%;
  }

  .header {
    z-index: 1;
  }
`;

const HeadingContainer = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
`;

const RememberMeContainer = styled.div`
  margin-top: 1.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
`;

// const BottomLinkContainer = styled.div`
//   margin: 1rem 0;
//   padding-bottom: 2rem;
//   border-bottom: 1px solid #e0e0e0;
// `;

type Values = {
  username: string;
  password: string;
};

const initialValues: Values = {
  username: "",
  password: "",
};

const FormSchema = Yup.object().shape({
  username: Yup.string().required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
});

const Login = () => {
  const navigate = useNavigate();
  const { setPass } = useUser();
  const [error, setError] = useState<string>();
  const { setLoading } = useLoading();
  const [rememberMe, setRememberMe] = useState<boolean | undefined>(false);

  const { setIsForgotPasswordModalOpen, isForgotPasswordModalOpen } = useUser();

  const fetchRefreshToken = async () => {
    const refresh = AppStorage.getRefresh();
    const payload = { refresh: refresh };

    try {
      const { data } = await getRefreshTokenAPI(payload);

      AppStorage.setToken(data.access);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSend = async (values: Values) => {
    setLoading(true);
    if (rememberMe) {
      AppStorage.set("@app:username", values.username);
    } else {
      AppStorage.remove("@app:username");
    }

    try {
      const payload = {
        username: onlyNumbers(values.username),
        password: values.password,
      };

      const {
        data: { access, refresh },
      } = await getTokenAPI(payload);

      AppStorage.setToken(access);
      AppStorage.setRefresh(refresh);

      if (access && refresh) {
        await fetchRefreshToken();
        setLoading(false);
        navigate("/app");
        setPass(values.password);
        AppStorage.set("@app:pass", values.password);
      }
    } catch (error) {
      console.error("Error while trying to login:", error);
      setError(String(error));
      setLoading(false);
    }
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: FormSchema,
      onSubmit: handleSend,
    });
  const handleChangeRememberMe = useCallback(() => {
    const newRememberMe = !rememberMe;
    setRememberMe(newRememberMe);
    AppStorage.set("@app:rememberMe", String(newRememberMe));
  }, [rememberMe]);

  useEffect(() => {
    const storedUsername = AppStorage.get("@app:username");
    if (storedUsername) {
      setFieldValue("username", storedUsername);
    }

    const storedRememberMe = AppStorage.get("@app:rememberMe");
    if (storedRememberMe !== null) {
      setRememberMe(storedRememberMe === "true");
    }
  }, [setFieldValue]);

  return (
    <LoginContainer>
      <Grid>
        <Column lg={7}>
          <Padding pt={40} x={16}>
            <Form onSubmit={handleSubmit}>
              <img
                style={{ height: "90px", position: "relative", left: "-10px" }}
                src={BanvoxLogo}
                alt=""
              />
              <Margin mb={-22} />
              <Heading style={{ fontSize: "30px" }}>Portal web</Heading>
              <Margin mb={50} />
              <HeadingContainer>
                <Heading>Efetuar login</Heading>
              </HeadingContainer>

              <Stack gap={5}>
                {error && (
                  <InlineNotification
                    title="Erro!"
                    // subtitle={error}
                    subtitle="Nenhuma conta encontrada com as credênciais fornecidas."
                    hideCloseButton
                    kind="error"
                    lowContrast
                  />
                )}

                <p
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "300",
                  }}
                  className="m-0 p-0"
                >
                  Digite seu CPF
                </p>
                <MaskedTextInput
                  id="username"
                  mask="999.999.999-99"
                  onChange={handleChange("username")}
                  placeholder="000.000.000-00"
                  value={values.username}
                />
                <div className="relative flex flex-col">
                  <PasswordInput
                    id="password"
                    labelText="Senha"
                    placeholder="Digite sua senha"
                    type="password"
                    onChange={handleChange("password")}
                    value={values.password}
                    helperText={errors.password}
                    required
                  />
                  <div
                    className="absolute flex m-0 p-0"
                    style={{
                      top: "0",
                      right: "0"
                    }}
                  >
                    <h5
                      onClick={() =>
                        setIsForgotPasswordModalOpen((prev) => !prev)
                      }
                      className="font-semibold text-blue-600 cursor-pointer text-[14px] m-0 p-0"
                    >
                      Esqueceu sua senha?
                    </h5>
                  </div>
                </div>
                <Button
                  className="form-btn"
                  type="submit"
                  renderIcon={ArrowRight}
                >
                  Continuar
                </Button>

                <RememberMeContainer>
                  <Checkbox
                    id="remember-me"
                    labelText="Lembrar de mim"
                    checked={rememberMe}
                    onChange={() => handleChangeRememberMe()}
                  />
                </RememberMeContainer>

                {/* <BottomLinkContainer>
                  <p>Não tem uma conta?</p>
                  <Margin mb={16} />

                  <Button
                    className="form-btn"
                    kind="tertiary"
                    type="button"
                    renderIcon={ArrowRight}
                  >
                    Crie uma conta
                  </Button>
                </BottomLinkContainer> */}
                {/* 
                <p>
                  Esqueceu o email ou a senha?{" "}
                  <a href="/">Entre em contato com o help desk do Portal</a>
                </p> */}
              </Stack>
            </Form>
          </Padding>
        </Column>
        <Column lg={9}>
          <div className="right-image"></div>
        </Column>
      </Grid>
      <ForgotPasswordModal
        open={isForgotPasswordModalOpen}
        onClose={() => setIsForgotPasswordModalOpen(false)}
      />
    </LoginContainer>
  );
};

export default Login;
