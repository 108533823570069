import { Button } from "prosperita-dumbo-react";
import lockicon from "~/assets/lock.png";
import Margin from "~/components/Margin";
import arrowIcon from "~/assets/arrowreverse.png"
import { useNavigate } from "react-router-dom";
type Irestrict = {
    text: string,
}

export const AccessRestricted = ({ text }: Irestrict) => {

    const navigate = useNavigate()

    return (
        <div>
            <div className="flex justify-end w-[99%] mx-auto py-0 mt-[60px] gap-[0px]">
            </div>
            <div className="flex flex-col justify-center w-[95%] mx-auto mt-0">
                <img src={lockicon} className="w-[160px]" alt="Ícone de lupa" />
                <div className="flex flex-col justify-center w-full mx-auto">
                    <h3 className="mt-4 text-[20px] font-normal leading-[28px] text-[#161616] font-['IBM Plex Sans']">
                        Acesso restrito
                    </h3>
                    <h4 className="mt-2 text-[15px] font-normal leading-[28px] text-[#161616] font-['IBM Plex Sans']">
                        {text}
                    </h4>
                </div>
                <Margin mt={25} />
                <div className="w-[95%] mx auto flex justify-start">
                    <Button
                        label="Retornar ao painel"
                        kind="primary"
                        size="large"
                        onClick={() => navigate("/app")}
                        icon={arrowIcon} />
                </div>
            </div>
        </div>
    );
};
