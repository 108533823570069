import { Modal, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import MaskedTextInput from "~/components/InputMask";
import { getFundsAPI } from "~/services/api/fund";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import useFund from "~/hooks/useFund";
import { ICompanyType, IFund } from "~/types";
import Label from "~/components/Label";
import Select, { MultiValue, SingleValue } from "react-select";
import {
  getCompanysAPI,
  postCompanyAPI,
  updateCompanyAPI,
} from "~/services/api/companys";
import { IPostProfileBody, PostProfileAPI } from "~/services/api/profile";
import { postCompanyFundAPI } from "~/services/api/companyFund";
import { getCompanyTypesAPI } from "~/services/api/company-types";
import useCompanyType from "~/hooks/useCompanyType";
import useCompany from "~/hooks/useCompany";

const notifySuccessCompany = () =>
  toast.success("Empresa cadastrada com sucesso!");
const notifyErrorCompany = () => toast.error("Algo saiu errado");

const notifySuccessProfile = () =>
  toast.success(
    "Usuário Master associado à empresa com sucesso! Enviaremos um email com as instruções de acesso a plataforma!"
  );
// const notifyErrorProfile = () =>
//   toast.error(
//     "Não foi possível associar o Usuário Master. Tente novamente mais tarde."
//   );

type Values = {
  name: string;
  document_number: string;
  brand_name: string;
  fund: string[];
  master_email: string;
  master_name: string;
  master_document_number: string;
  company_type: {
    id: number;
    name: string;
  };
};

const initialValues: Values = {
  name: "",
  brand_name: "",
  document_number: "",
  fund: [],
  master_email: "",
  master_document_number: "",
  master_name: "",
  company_type: { id: -1, name: "" },
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sort_name: Yup.string().required("Nome abreviado da tarefa é obrigatório"),
  document_number: Yup.string().required("Nome da tarefa é obrigatório"),
  fund: Yup.array().required("Fundo é obrigatório"),
});

const NewCompanyModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setFunds, funds } = useFund();
  const { setCompanyTypes, companyTypes } = useCompanyType();

  const { setOpenNewCompanyModal, setCompanys } = useCompany();

  const {
    handleSubmit,
    resetForm,
    setFieldValue,
    values,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const resetFormFields = useCallback(() => {
    resetForm();
    setFieldValue("fund", []);
    setFieldValue("company_type", {});
  }, [resetForm, setFieldValue]);

  type SelectOption = {
    value: string;
    label: string;
  };

  const handleSelectFunds = (newValue: MultiValue<SelectOption>) => {
    if (newValue) {
      setFieldValue(
        "fund",
        newValue.map((option) => option.value)
      );
    } else {
      setFieldValue("fund", []);
    }
  };

  const handleSelectCompanyType = (
    newValue: SingleValue<{
      value: { id: number; name: string };
      label: string;
    }>
  ) => {
    if (newValue) {
      setFieldValue("company_type", newValue.value);
    } else {
      setFieldValue("company_type", {});
    }
  };

  const fundsOptions = useMemo(() => {
    return funds
      ?.filter((fund) => {
        return fund.raw_data.active;
      })
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund.raw_data?.short_name ?? fund?.name} - ${formatCPFCNPJ(fund.raw_data?.document_number)} `,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  const companyTypesOptions = useMemo(() => {
    return companyTypes
      ?.map((companyType: ICompanyType) => ({
        value: { id: Number(companyType.id), name: companyType.name },
        label: companyType.name,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [companyTypes]);

  //   const sendNewTaskPayload: ISendNewAreaBody = useMemo(() => {
  //     return {
  //       name: values.name,
  //     };
  //   }, [values]);

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();

      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchCompanyTypes = useCallback(async () => {
    try {
      const { data } = await getCompanyTypesAPI();
      setCompanyTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanyTypes]);

  const payloadCompany = useMemo(() => {
    return {
      name: values.name,
    };
  }, [values.name]);

  const fetchCompanys = useCallback(async () => {
    try {
      const { data } = await getCompanysAPI();
      setCompanys(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanys]);

  const fetchSendNewCompany = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await postCompanyAPI(payloadCompany);
      setOpenNewCompanyModal(false);

      notifySuccessCompany();

      const payloadMasterUser: IPostProfileBody = {
        name: values.master_name,
        document_number: onlyNumbers(values.master_document_number),
        type: ["manager"],
        company: data.id,
        email: values.master_email,
        raw_data: {
          first_login: true,
        },
      };

      notifySuccessProfile();

      const { data: profileData } = await PostProfileAPI(payloadMasterUser);

      await updateCompanyAPI(
        {
          raw_data: {
            active: true,
            brand_name: values.brand_name,
            document_number: onlyNumbers(values.document_number),
            company_type: values.company_type,
            master_user: {
              id: profileData.id,
              full_name: profileData.name,
              document_number: profileData.document_number,
            },
          },
        },
        String(data.id)
      );

      const promises = values.fund.map((fundId) => {
        return postCompanyFundAPI({
          company: data.id,
          fund: Number(fundId),
        });
      });

      await Promise.all(promises);

      fetchCompanys();
      resetForm();
      setFieldValue("fund", []);
      resetFormFields(); //
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      notifyErrorCompany();
    }
  }, [
    setLoading,
    payloadCompany,
    values,
    resetForm,
    resetFormFields,
    setFieldValue,
    fetchCompanys,
    setOpenNewCompanyModal,
  ]);

  useEffect(() => {
    fetchFunds();
    fetchCompanyTypes();
  }, [fetchFunds, fetchCompanyTypes]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        modalHeading="Nova empresa"
        size="sm"
        open={open}
        onRequestClose={() => {
          onClose();
          resetForm();
          setFieldValue("fund", []);
          resetFormFields();
        }}
        primaryButtonDisabled={
          values.name === "" ||
          values.brand_name === "" ||
          onlyNumbers(values.document_number).length < 14 ||
          onlyNumbers(values.master_document_number).length < 11 ||
          values.master_email === "" ||
          values.master_name === "" ||
          values.fund.length === 0
        }
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewCompany();
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Razão Social"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          placeholder="Ex: MaxInvest Gestão LTDA"
        />
        <Margin mb={10} />
        <TextInput
          id="brand_name"
          name="brand_name"
          labelText="Nome Fantasia"
          onChange={handleChange}
          placeholder="Ex: MaxInvest"
          onBlur={handleBlur}
          value={values.brand_name}
        />
        <Margin mb={20} />
        <p style={{ fontSize: "12px", color: "black", fontWeight: "300" }}>
          {" "}
          CNPJ
        </p>
        <Margin mb={5} />
        <MaskedTextInput
          mask="99.999.999/9999-99"
          id="document_number"
          onChange={handleChange}
          name="document_number"
          placeholder="00.000.000/0000-00"
          value={values.document_number}
        />
        <Margin mb={20} />
        <Label text="Selecione o tipo de empresa" />
        <Select
          options={companyTypesOptions}
          onChange={handleSelectCompanyType}
          placeholder="Pesquisar ou selecionar tipo de empresa..."
        />{" "}
        <Margin mb={25} />
        <h4>Associar Fundos</h4>
        <Margin mb={10} />
        <Label text="Selecione os fundos que deseja associar à esta empresa" />
        <Select
          options={fundsOptions}
          onChange={handleSelectFunds}
          placeholder="Pesquisar ou selecionar fundos..."
          isMulti
        />{" "}
        <Margin mb={25} />
        <h4>Usuário Master</h4>
        <Margin mb={10} />
        <TextInput
          id="master_name"
          name="master_name"
          labelText="Nome completo"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.master_name}
          placeholder="Ex: Jhon Doe"
        />
        <Margin mb={20} />
        <p style={{ fontSize: "12px", color: "black", fontWeight: "300" }}>
          {" "}
          CPF
        </p>
        <MaskedTextInput
          mask="999.999.999-99"
          id="master_document_number"
          onChange={handleChange}
          name="master_document_number"
          placeholder="000.000.000-00"
          value={values.master_document_number}
        />
        <Margin mb={15} />
        <TextInput
          id="master_email"
          name="master_email"
          labelText="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.master_email}
          placeholder="seuemail@email.com"
        />
        <Margin mb={104} />
      </Modal>
    </form>
  );
};

export default NewCompanyModal;
