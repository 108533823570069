import ApiService from "../api";

export type ISendNewPassword = {
  current_password: string;
  new_password: string;
};

export const postNewPasswordAPI = (data: ISendNewPassword) => {
  return ApiService.HttpPost({
    route: "change-password/",
    body: data,
    token: true,
  });
};

export type IForgotPassword = {
  user: string;
};

export const postForgotPassword = (data: IForgotPassword) => {
  return ApiService.HttpPost({
    route: "forgot-password/",
    body: data,
    token: false,
  });
}
