import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import useUser from "~/hooks/useUser";
import {
  deleteBeneficiarykeyPIX,
  getBeneficiarieskeyPIX,
  getBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { useParams } from "react-router-dom";


const TypeKeysLabel: { [key: string]: string } = {
  cnpj: "CNPJ",
  cpf: "CPF",
  telefone: "Celular",
  evp: "Chave Aleatoria",
  email: "Email",
};

export const DeleteModalKeyPix = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { id } = useParams();

  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setaddkeyPixModal,
    pixkeyUser,
    setdeleteModalKeyPix,
    setRenderPixKeys
  } = useBeneficiary();

  const notifySuccess = () => toast.success("Chave removida com sucesso!");
  const notifyError = () => toast.error("Não foi remover a Chave");


  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setBeneficiaryDetails]);

  const fetchPIXkeysBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiarieskeyPIX(Number(pixkeyUser?.beneficiary))
      setRenderPixKeys(data.results)
    } catch (err) {
      console.warn(err)
    }
  }, [pixkeyUser])

  const fetchDeleteKeyPIXbeneficiary = useCallback(
    async () => {
      setLoading(true);
      try {
        setdeleteModalKeyPix(false);
        await fetchBeneficiary();
        await deleteBeneficiarykeyPIX(Number(pixkeyUser?.id))
        fetchPIXkeysBeneficiary()
        setaddkeyPixModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [fetchBeneficiary, pixkeyUser, userProfile, beneficiaryDetails]
  );

  useEffect(() => {
    fetchBeneficiary();
  }, []);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Remover Chave Pix Cadastrada"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchDeleteKeyPIXbeneficiary()
      }}
    >
      <>
        <Margin mt={20} />
        <h3 style={{ textAlign: "center" }}>
          Deseja realmente remover a Chave PIx Abaixo?
        </h3>
        <Margin mt={10} />
        {/* <h4>
          <span style={{ fontWeight: "bold" }}>Banco:</span>
          {pixkeyUser?.bank} - {pixkeyUser?.bank_name}
        </h4>
        <h4>
          <span style={{ fontWeight: "bold" }}>Nome: </span>
          {pixkeyUser?.name}
        </h4> */}
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Chave: </span>
          {pixkeyUser?.key}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Tipo: </span>
          {TypeKeysLabel[pixkeyUser?.type || "Não encontrada"]}
        </h4>
      </>

      <Margin mb={40} />
    </Modal>
  );
};
