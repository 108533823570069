import {
  Button,
  Column,
  Grid,
  Dropdown,
  TextInput,
  PasswordInput,
  Modal,
} from "@carbon/react";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import Margin from "~/components/Margin";
import * as Yup from "yup";
import {
  IPostProfileBody,
  PostProfileAPI
} from "~/services/api/profile";
import { IDropdownItem } from "~/types";
import { useFormik } from "formik";
import useProfile from "~/hooks/useProfile";
import ErrorIcon from "~/assets/Misuse.png";
import CheckIcon from "~/assets/Checkmark--filled.png";
import Label from "~/components/Label";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import MaskedTextInput from "~/components/InputMask";
import { onlyNumbers } from "~/services/utils";

const ContainerClient = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          animation: ${fadeIn} 0.3s ease-in-out;
        `
      : css`
          opacity: 0;
          height: 0;
          overflow: hidden;
        `}
`;

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

const Container = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          animation: ${fadeIn} 0.3s ease-in-out;
        `
      : css`
          opacity: 0;
          height: 0;
          overflow: hidden;
        `}
`;

type Values = {
  full_name: string;
  document_number: string;
  company_name: string;
  company_document_number: string;
  brand_name: string;
  email: string;
  password: string;
  confirm_password: string;
};

const initialValues: Values = {
  full_name: "",
  document_number: "",
  company_name: "",
  company_document_number: "",
  brand_name: "",
  email: "",
  password: "",
  confirm_password: "",
};

const validationSchema = Yup.object({
  full_name: Yup.string().required("Nome completo é obrigatório"),
  document_number: Yup.string().required("Número do documento é obrigatório"),
  company_name: Yup.string().required("Nome da empresa é obrigatório"),
  company_document_number: Yup.string().required(
    "Número do documento da empresa é obrigatório"
  ),
  brand_name: Yup.string().required("Nome da marca é obrigatório"),
  email: Yup.string().email("Email inválido").required("Email é obrigatório"),
  password: Yup.string()
    .min(8, "A senha deve ter pelo menos 6 caracteres")
    .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
    .matches(/\d/, "A senha deve conter pelo menos um número")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "A senha deve conter pelo menos um caractere especial"
    )
    .required("Senha é obrigatória"),
  confirm_password: Yup.string()
    .min(6, "A senha deve ter pelo menos 6 caracteres")
    .required("Senha é obrigatória"),
  profile: Yup.string().required("Usuário é obrigatório"),
  type_profile: Yup.string().required("Tipo de usuário é obrigatório"),
});

const options: IDropdownItem[] = [
  {
    id: "tool_manager",
    text: "Gestor de ferramenta",
  },
  {
    id: "area_manager",
    text: "Gestor de àrea",
  },
  {
    id: "internal",
    text: "Atendente",
  },
];

const General = () => {
  const navigate = useNavigate();

  const {
    createPasswordModalOpen,
    setCreatePasswordModalOpen,
    selectedItem,
    setSelectedItem,
  } = useProfile();
  const { setLoading } = useLoading();
  const { values, handleChange, touched, errors, resetForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  //   const handleShowToast = () => {
  //     createProfile();
  //     navigate("/app/profiles/");
  //   };

  const minLength = values.password.length >= 8;
  const hasNumber = /[0-9]/.test(values.password);
  const hasUpperCase = /[A-Z]/.test(values.password);
  const hasLowerCase = /[a-z]/.test(values.password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(values.password);

  const disabledButtonWhenTypeIsClient = useMemo(() => {
    return (
      Boolean(errors.email) ||
      !(values.full_name && values.full_name.trim().split(/\s+/).length >= 2) ||
      !(
        values.company_name &&
        values.company_name.trim().split(/\s+/).length >= 2
      ) ||
      values.brand_name === "" ||
      onlyNumbers(values.document_number).length < 11 ||
      onlyNumbers(values.company_document_number).length < 14
    );
  }, [
    errors,
    values.full_name,
    values.company_name,
    values.brand_name,
    values.document_number,
    values.company_document_number,
  ]);

  const disabledButton = useMemo(() => {
    return (
      Boolean(errors.email) ||
      !(values.full_name && values.full_name.trim().split(/\s+/).length >= 2) ||
      onlyNumbers(values.document_number).length < 11
    );
  }, [errors, values.full_name, values.document_number]);

  const handleDropdownChange = ({
    selectedItem,
  }: {
    selectedItem: IDropdownItem | null;
  }) => {
    if (selectedItem) {
      setSelectedItem(selectedItem.id);
    }
  };
  const notifySuccess = () => toast.success("novo usuário criado com sucesso!");
  const notifyError = () =>
    toast.error("Não foi possível criar o usuário Tente novamente mais tarde.");

  const payload: IPostProfileBody = useMemo(() => {
    return {
      name: values.full_name,
      document_number: onlyNumbers(values.document_number),
      type: [selectedItem],
      email: values.email,
      raw_data: {
        first_login: true,
      },
    };
  }, [values, selectedItem]);

  const createProfile = useCallback(async () => {
    setLoading(true);
    setCreatePasswordModalOpen(false);
    try {
      await PostProfileAPI(payload);
      resetForm();
      setSelectedItem("");
      setLoading(false);
      navigate("/app/profiles/");
      notifySuccess();
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      setFieldValue;
      notifyError();
    }
  }, [
    payload,
    resetForm,
    navigate,
    setLoading,
    setCreatePasswordModalOpen,
    setSelectedItem,
    setFieldValue,
  ]);
  return (
    <>
      {selectedItem !== "" ? (
        <h2>Geral</h2>
      ) : (
        <h3>Primeiro, selecione o tipo de usuário</h3>
      )}

      <Dropdown
        id="my-dropdown"
        titleText="Tipo de usuário"
        label="Selecione"
        items={options}
        itemToString={(item) => (item ? item.text : "")}
        onChange={handleDropdownChange}
      />

      <Container isVisible={selectedItem !== ""}>
        <TextInput
          id="full_name"
          name="full_name"
          labelText="Nome completo"
          placeholder="Digite o nome completo"
          onChange={handleChange}
          value={values.full_name}
        />
        <Margin mb={20} />

        <p style={{ fontSize: "12px", color: "black", fontWeight: "300" }}>
          {" "}
          CPF
        </p>
        <MaskedTextInput
          mask="999.999.999-99"
          id="document_number"
          onChange={handleChange}
          name="document_number"
          placeholder="Digite o CPF"
          value={values.document_number}
        />
        <Margin mb={20} />
        <TextInput
          id="email"
          name="email"
          onChange={handleChange}
          value={values.email}
          labelText="Email"
          placeholder="Digite o email"
        />
        <Margin mb={20} />
        <ContainerClient isVisible={selectedItem === "client"}>
          <Margin mb={40} />
          <h2>Dados da Empresa</h2>
          <Margin mb={20} />
          <TextInput
            id="company_name"
            name="company_name"
            value={values.company_name}
            onChange={handleChange}
            labelText="Razão Social"
            placeholder="Digite a Razão social"
          />
          <Margin mb={20} />
          <p style={{ fontSize: "12px", color: "black", fontWeight: "300" }}>
            {" "}
            CNPJ
          </p>
          <Margin mb={5} />
          <MaskedTextInput
            mask="99.999.999/9999-99"
            id="company_document_number"
            onChange={handleChange}
            name="company_document_number"
            placeholder="Digite o CNPJ"
            value={values.company_document_number}
          />
          <Margin mb={20} />
          <TextInput
            id="brand_name"
            name="brand_name"
            onChange={handleChange}
            value={values.brand_name}
            labelText="Nome fantasia"
            placeholder="Digite o nome fantasia"
          />
        </ContainerClient>
        {/* <Margin mb={40} />
                        <h2>Crie uma senha</h2>
                        <Margin mb={20} />
                        <PasswordInput
                          id="password"
                          labelText="Senha"
                          placeholder="Digite a senha do usuário"
                        />
                        <Margin mb={20} />
                        <PasswordInput
                          id="confirm_password"
                          labelText="Confirmação de senha"
                          placeholder="Confirme a senha do usuário"
                        /> */}
        <Column span={12}>
          <Margin mb={64} />
          <Grid>
            <Column span={6}>
              <Button
                kind="tertiary"
                className="action-button"
                onClick={() => navigate("/app/profiles/")}
              >
                Cancelar
              </Button>
            </Column>
            <Column span={6}>
              <Button
                disabled={
                  selectedItem === "client"
                    ? disabledButtonWhenTypeIsClient
                    : disabledButton
                }
                kind="primary"
                className="action-button"
                onClick={() => createProfile()}
              >
                Concluir
              </Button>
            </Column>
          </Grid>
        </Column>
      </Container>
      <Modal
        style={{ zIndex: 3 }}
        modalHeading=""
        size="sm"
        open={createPasswordModalOpen}
        onRequestClose={() => {
          setCreatePasswordModalOpen(false);
        }}
        primaryButtonDisabled={
          Boolean(errors.password) ||
          values.password !== values.confirm_password
        }
        primaryButtonText="Concluir cadastro"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          createProfile();
        }}
      >
        <Margin mt={20} />
        <h3>Agora, vamos criar uma senha</h3>
        <div>
          <Margin mb={50} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Margin mt={5} />
              <img src={minLength ? CheckIcon : ErrorIcon} alt="" />
            </div>
            <Margin ml={10} />
            <p>Mínimo 8 caracteres</p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Margin mt={5} />
              <img src={hasNumber ? CheckIcon : ErrorIcon} alt="" />
            </div>
            <Margin ml={10} />
            <p>Mínimo de 1 número</p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Margin mt={5} />
              <img
                src={hasLowerCase && hasUpperCase ? CheckIcon : ErrorIcon}
                alt=""
              />
            </div>
            <Margin ml={10} />
            <p>Letras maiúsculas e minúsculas</p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Margin mt={5} />
              <img src={hasSpecialChar ? CheckIcon : ErrorIcon} alt="" />
            </div>
            <Margin ml={10} />
            <p>Caracteres especiais</p>
          </div>
          <Margin mt={20} />
          <PasswordInput
            id="password"
            labelText="Senha"
            value={values.password}
            onChange={handleChange}
            placeholder="Digite a senha do usuário"
            invalid={Boolean(touched.password && errors.password)}
            invalidText={
              touched.password && errors.password ? errors.password : ""
            }
          />

          <Margin mb={20} />
          <PasswordInput
            id="confirm_password"
            labelText="Confirmação de senha"
            value={values.confirm_password}
            onChange={handleChange}
            placeholder="Confirme a senha do usuário"
          />
          <Margin mt={3} />
          <Label
            text={
              values.confirm_password.length > 0 &&
                values.confirm_password !== values.password
                ? `As senhas não coincidem. Verifique-as por favor`
                : ""
            }
          />

          <Margin mb={60} />
        </div>
      </Modal>
    </>
  );
};

export default General;
