import { Modal, TextInput } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import useUser from "~/hooks/useUser";
import Select from "react-select";

import { useFormik } from "formik";
import {
  getBeneficiariesBankAccount,
  getBeneficiaryAPI,
  IbeneficiaryBankAccount,
  postBeneficiaryBankAccount,
} from "~/services/api/beneficiaries";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { ITypeBanks } from "~/types";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

type IAccount = {
  account_number: string;
  account_number_digit: string;
  agency: string;
  bank: string;
  bank_name: string;
};

type ImescleOptionsAccountPix = IAccount;

const StyledTextInput = styled(TextInput)`
  & input[type="number"]::-webkit-inner-spin-button,
  & input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const NewAccountModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { banks, setBanks } = useBanks();
  const { id } = useParams();

  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setEditModalBeneficiary,
    accountBeneficiary,
    setAccountBeneficiary
  } =
    useBeneficiary();


  const notifySuccess = () =>
    toast.success("Conta Bancária cadastrada com sucesso!");
  const notifyError = () =>
    toast.error(
      "não foi possivel cadastrar a conta. Tente novamente mais tarde."
    );

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);


  const validationSchema = Yup.object({
    bank: Yup.string().required("O banco é obrigatório"),
    agency: Yup.string()
      .matches(/^\d+$/, "A agência deve conter apenas números")
      .required("A agência é obrigatória"),
    account_number: Yup.string()
      .matches(/^\d+$/, "A conta deve conter apenas números")
      .required("O número da conta é obrigatório"),
  });
  const InitialValues: Omit<ImescleOptionsAccountPix, "account_number_digit"> =
  {
    account_number: "",
    agency: "",
    bank: "",
    bank_name: "",
  };
  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    validateForm,
    setTouched,
    resetForm,
  } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });


  const banksOptions = useMemo(() => {
    return banks
      ?.map((banks: ITypeBanks) => ({
        value: String(banks.id),
        label: String(`${banks.code} - ${banks.description}`),
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [banks]);

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, beneficiaryDetails]);

  const fetchBankAccountBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesBankAccount(Number(id))
      setAccountBeneficiary(data.results.sort((a, b) => b.id - a.id));
    } catch (err) {
      console.log(err)
    }

  }, [accountBeneficiary])

  const payload: IbeneficiaryBankAccount = useMemo(() => {
    return {
      agency: values.agency,
      account_number: values.account_number,
      account_number_digit:
        values.account_number.split("")[
        values.account_number.length - 1
        ],
      profile: Number(userProfile?.id),
      beneficiary: Number(id),
      bank: Number(values.bank),
      status: "waiting_approval",
      active: false,
    };
  }, [values, beneficiaryDetails, userProfile]);

  const fetchAccountBeneficiary = useCallback(
    async () => {
      setLoading(true);
      try {
        await postBeneficiaryBankAccount(payload)
        await fetchBankAccountBeneficiary()
        setEditModalBeneficiary(false);
        setLoading(false);
        notifySuccess();
        resetForm();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [payload, fetchBeneficiary, accountBeneficiary]
  );

  useEffect(() => {
    fetchBeneficiary();
    fetchBanks();
  }, []);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Adicionar nova conta bancária"
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
        resetForm();
      }}
      primaryButtonText="Concluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        const formErrors = await validateForm();
        if (Object.keys(formErrors).length === 0) {
          await fetchAccountBeneficiary();
          onClose();
        } else {
          setTouched({
            bank: true,
            agency: true,
            account_number: true,
          });
        }
      }}
    >
      {" "}
      <Margin mb={20} />
      <h3 style={{ textAlign: "center" }}>
        Adicionar uma conta bancária para {beneficiaryDetails?.name}
      </h3>
      <Margin mb={80} />
      <p> Banco</p>
      <Select
        options={banksOptions}
        placeholder="Pesquisar ou selecionar banco..."
        name="bank"
        onChange={(e) => {
          setFieldValue("bank", e?.value);
          setFieldValue("bank_name", e?.label);
        }}
      />
      <Margin mb={20} />
      <Margin mb={10} />
      <StyledTextInput
        id="agency"
        name="agency"
        labelText="Agência"
        placeholder="0000"
        onChange={handleChange}
        inputMode="numeric"
        maxLength={5}
        value={values.agency}
      />
      {touched.agency && errors.agency ? <div>{errors.agency}</div> : null}
      <Margin mb={20} />
      <Margin mb={5} />
      <StyledTextInput
        id="account_number"
        name="account_number"
        labelText="Conta (com dígito)"
        placeholder="00000000"
        onChange={handleChange}
        inputMode="numeric"
        maxLength={12}
        value={values.account_number}
      />
      {touched.account_number && errors.account_number ? (
        <div>{errors.account_number}</div>
      ) : null}
      <Margin mb={20} />
      <Margin mb={110} />
    </Modal>
  );
};

export default NewAccountModal;
