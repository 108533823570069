import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import {
  getBeneficiaryFundWIDAPI,
  IBodyPatchBeneficiary,
  patchBeneficiaryAPI,
  patchBeneficiaryFundAPI,
} from "~/services/api/beneficiaries";
import { toast } from "react-toastify";
import { formatCPFCNPJ } from "~/services/utils";
import { useParams } from "react-router-dom";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

const ReproveFundBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    beneficiaryFundDetails,
    setApproveBeneficiaryFundModal,
    setReproveBeneficiaryFundModal,
    setFundsBeneficiaryRender,
    beneficiaryDetails,
  } = useBeneficiary();
  const { id } = useParams();
  const notifySuccess = () =>
    toast.success("Associação de fundo reprovada com sucesso");
  const notifyError = () =>
    toast.error(
      "Não foi possível reprovar a associação de fundo. Tente novamente mais tarde."
    );

  const payload: IBodyPatchBeneficiary = useMemo(() => {
    return {
      raw_data: {
        status: beneficiaryDetails?.status,
        pending_funds_approvations: false,
      },
    };
  }, [beneficiaryDetails]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      try {
        await patchBeneficiaryAPI(id, payload);
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [payload]
  );

  const fetchFundsBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryFundWIDAPI(Number(id));
      setFundsBeneficiaryRender(data?.results.reverse());

      if (
        !data.results?.some(
          (fundBeneficiary) =>
            fundBeneficiary.raw_data.status === "waiting_approval"
        )
      ) {
        beneficiaryDetails &&
          fetchPatchBeneficiary(Number(beneficiaryDetails.id));
        console.log("A");
      }
    } catch {
      console.warn("erro");
    }
  }, [id, setFundsBeneficiaryRender]);

  const fetchApproveFundBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryFundAPI(id, {
          raw_data: {
            status: "reproved",
          },
        });
        await fetchFundsBeneficiary();
        setReproveBeneficiaryFundModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [setLoading, fetchFundsBeneficiary, setApproveBeneficiaryFundModal]
  );

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Reprovar associação de fundo"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, reprovar"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        beneficiaryFundDetails &&
          fetchApproveFundBeneficiary(Number(beneficiaryFundDetails.id));
      }}
    >
      <Margin mt={20} />
      <h3 style={{ textAlign: "start" }}>
        Deseja reprovar a associação deste fundo?
      </h3>
      <Margin mb={40} />
      <h4 style={{ fontWeight: "bold" }}>
        {"Nome Abreviado: "}

        <span style={{ fontWeight: "normal" }}>
          {beneficiaryFundDetails?.fund.raw_data.short_name}
        </span>
      </h4>

      <h4 style={{ fontWeight: "bold" }}>
        {"CNPJ: "}

        <span style={{ fontWeight: "normal" }}>
          {beneficiaryFundDetails?.fund.raw_data.document_number &&
            formatCPFCNPJ(
              beneficiaryFundDetails?.fund.raw_data.document_number
            )}
        </span>
      </h4>
      <h4 style={{ fontWeight: "bold" }}>
        {"Nome Completo: "}

        <span style={{ fontWeight: "normal" }}>
          {beneficiaryFundDetails?.fund.name}
        </span>
      </h4>

      <Margin mb={100} />
    </Modal>
  );
};

export default ReproveFundBeneficiaryModal;
