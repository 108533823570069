import { Grid, Column } from "@carbon/react";
import editicon from "~/assets/editicon.png";

import renewicon from "~/assets/Renew.png";

import addicon from "~/assets/iconadd.png";

import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";

import { getAttendTypeAPI } from "~/services/api/type";
import useAttendType from "~/hooks/useType";
import NewAttendTypeContentModal from "./NewAttendTypeContentModal";
import DeleteAttendTypeContentModal from "./DeleteAttendTypeContentModal";
import EditAttendTypeModal from "./EditAttendTypeModal";
import useLoading from "~/hooks/useLoading";
import { TableBasic, Tag, Tooltip } from "prosperita-dumbo-react";

const AttendTypes = () => {
  const {
    setAttendTypes,
    attendTypes,
    openNewAttendTypeModal,
    setOpenNewAttendTypeModal,
    openDeleteAttendTypeModal,
    setOpenDeleteAttendTypeModal,
    setAttendTypesDetails,
    setOpenEditAttendTypeModal,
    openEditAttendTypeModal,
  } = useAttendType();
  const { setLoading } = useLoading();

  const dataAttendTypes = useMemo(() => {
    return attendTypes.map((attendType, index) => {
      return {
        Nome: (
          <div className="flex flex-col align-center">{attendType.name}</div>
        ),
        SLA: `${attendType?.sla} ${attendType.sla_type === "day" ? "Dia(s)" : attendType.sla_type === "hour" ? "Hora(s)" : "Minuto(s)"}`,
        Status: attendType.active ? (
          <div className="w-[4px] m-0 p-0">
            <Tag type="green" position="center">
              <p className="text-[14px] m-0 p-0">Ativo</p>
            </Tag>
          </div>
        ) : attendType?.active === false ? (
          <div className="w-[4px] m-0 p-0">
            <Tag type="magenta" position="center">
              <p className="text-[14px] m-0 p-0">Inativo</p>
            </Tag>
          </div>
        ) : (
          ""
        ),
        edit: (
          <Tooltip align="top" label="Editar" size="large">
            <div key={index} className="m-0 p-0">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenEditAttendTypeModal(true);
                  setAttendTypesDetails(attendType);
                }}
              >
                <img
                  style={{ objectFit: "contain" }}
                  alt="Editar"
                  src={editicon}
                />
              </button>
            </div>
          </Tooltip>
        ),
      };
    });
  }, [attendTypes, setAttendTypesDetails, setOpenEditAttendTypeModal]);

  const tableHeadersProfile: string[] = ["Nome", "SLA", "Status", ""];

  const fetchAttendTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      console.error(err);
    }
  }, [setAttendTypes, setLoading]);

  useEffect(() => {
    fetchAttendTypes();
  }, [fetchAttendTypes]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Tipos de atendimento</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableBasic
          search
          buttons={[
            {
              kind: "ghost",
              label: "",
              dimension: "37",
              width: "37px",
              onClick: () => fetchAttendTypes(),
              position: "right",
              icon: renewicon,
            },

            {
              kind: "primary",
              label: "Novo tipo de atendimento",
              dimension: "",
              onClick: () => setOpenNewAttendTypeModal(true),
              position: "right",
              icon: addicon,
            },
          ]}
          pageSizes={[10, 5, 20, 30, 40, 50]}
          rowData={dataAttendTypes}
          columnTitles={tableHeadersProfile}
        />
      </Column>
      <NewAttendTypeContentModal
        onClose={() => setOpenNewAttendTypeModal(false)}
        open={openNewAttendTypeModal}
      />
      <EditAttendTypeModal
        onClose={() => setOpenEditAttendTypeModal(false)}
        open={openEditAttendTypeModal}
      />

      <DeleteAttendTypeContentModal
        onClose={() => setOpenDeleteAttendTypeModal(false)}
        open={openDeleteAttendTypeModal}
      />
    </Grid>
  );
};

export default AttendTypes;
