import { Button } from "prosperita-dumbo-react"
import lupaicon from "~/assets/lupa.png"
import { AddProfileModal } from "./AddProfileModal"
import useProfile from "~/hooks/useProfile"
import addIcon from "~/assets/add.png"

type ItypeEmptyRegister = {
    openModal: () => void
}
export const EmptyRegister = ({ openModal }: ItypeEmptyRegister) => {

    const {
        isProfileFormOpenModal,
        setIsProfileFormOpenModal,
    } = useProfile();
    return (
        <div>
            <div className='justify-end flex w-[96%] mx-auto py-0 mt-0 gap-[20px]'>
                <Button onClick={openModal}
                    kind='primary'
                    size='large'
                    label="Adicionar  usuário"
                    icon={addIcon}
                ></Button>
            </div>
            <div className="flex justify-center flex-col w-[90%] mx-auto mt-15">
                <img src={lupaicon} className="w-[200px]" />
                <div className="flex flex-col justify-center w-[100%] mx-auto">
                    <h3 className="mt-3 text-[20px] font-normal leading-[28px] text-[#161616] font-['IBM Plex Sans']">Não há nenhum usuário para exibir</h3>
                    <h4 className="text-[15px] font-normal leading-[28px] text-[#161616] font-['IBM Plex Sans'] mt-2">Não há usuários para serem exibidos no momento, para registrar um novo usuário clique no botão direito localizado no canto superior.</h4>
                </div>
            </div>
            <AddProfileModal
                open={isProfileFormOpenModal}
                onClose={() => setIsProfileFormOpenModal(false)} />
        </div>
    )
}
