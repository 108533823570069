import constate from "constate";
import { useState } from "react";
import { IProfiles } from "~/types";

const [ProfileProvider, useProfile] = constate(() => {
  
  const [profiles, setProfiles] = useState<IProfiles[]>([]);
  const [profilesInCompany, setProfilesInCompany] = useState<IProfiles[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const [internalApprovers, setInternalApprovers] = useState<IProfiles[]>([]);
  const [newInternalApprovers, setNewInternalApprovers] =
    useState<boolean>(false);

  const [detailsProfile, setDetailsProfile] = useState<IProfiles>();
  const [openDeleteProfileModal, setOpenDeleteProfileModal] =
    useState<boolean>(false);

  const [openEditProfileModal, setOpenEditProfileModal] =
    useState<boolean>(false);

  const [createPasswordModalOpen, setCreatePasswordModalOpen] =
    useState<boolean>(false);

  const [isProfileFormOpenModal, setIsProfileFormOpenModal] =
    useState<boolean>(false);

  const [confirmedModalAddProfile, setConfirmedModalAddProfile] =
    useState<boolean>(false);

  const [editModalProfile, setEditModalProfile] =
    useState<boolean>(false)

  return {
    setEditModalProfile,
    editModalProfile,
    setConfirmedModalAddProfile,
    confirmedModalAddProfile,
    isProfileFormOpenModal,
    setIsProfileFormOpenModal,
    openEditProfileModal,
    setOpenEditProfileModal,
    internalApprovers,
    setInternalApprovers,
    profilesInCompany,
    setProfilesInCompany,
    profiles,
    setProfiles,
    createPasswordModalOpen,
    setCreatePasswordModalOpen,
    selectedItem,
    setSelectedItem,
    detailsProfile,
    setDetailsProfile,
    openDeleteProfileModal,
    setOpenDeleteProfileModal,
    newInternalApprovers,
    setNewInternalApprovers,
  };
});

export { ProfileProvider };

export default useProfile;
