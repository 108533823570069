import { Modal } from "@carbon/react";
import { SingleValue } from "react-select";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import useUser from "~/hooks/useUser";
import Select from "react-select";
import { useFormik } from "formik";
import {
  getBeneficiarieskeyPIX,
  getBeneficiaryAPI,
  postBeneficiarykeyPIX,
} from "~/services/api/beneficiaries";
import { useParams } from "react-router-dom";
import MaskedTextInput from "~/components/InputMask";
import { IkeypixType, postCheckpixAPI } from "~/services/api/checkpix";
import { Loading } from "prosperita-dumbo-react";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";

type IBankAccountInfoPix = {
  account_number: string;
  account_number_digit: string;
  account_type: string;
  agency: string;
  bank: string;
  bank_name: string;
  document_number: string;
  key: string;
  key_type: string;
  message: string;
  name: string;
};
type InitialValues = {
  cnpj: string;
  cpf: string;
  telefone: string;
  evp: string;
  email: string;
  type: string;
  bank: string;
};

const initialValues: InitialValues = {
  cnpj: "",
  cpf: "",
  telefone: "",
  evp: "",
  email: "",
  type: "",
  bank: "",
};

type SelectOption = {
  value: string;
  label: string;
};

type MaskTypeOptions = {
  [key: string]: string;
};

const MaskIndex: MaskTypeOptions = {
  cnpj: "99.999.999/9999-99",
  cpf: "999.999.999-99",
  telefone: "(99)99999-9999",
};


export const AddModalPixkey = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {

  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { id } = useParams();
  const [isValidPixKey, setIsValidPixKey] = useState<IBankAccountInfoPix>();
  const [stateValuesPix, setstateValuesPix] = useState<SelectOption[]>([]);
  const [step, setStep] = useState("initial");
  const [disabledButtonSubmit, setDisabledButtonSubmit] = useState<boolean>(false)

  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setaddkeyPixModal,
    setRenderPixKeys,
    renderPixKeys
  } =
    useBeneficiary();

  const [stepLoading, setStepLoading] = useState(false);
  const [pagekeyPixError, setpagekeyPixError] = useState<boolean>(false);

  const notifySuccess = () => toast.success("Chave adicionada com sucesso!");
  const notifyError = () => toast.error("Não foi possivel adicionar a Chave");
  const notifyErrorkeyPix = () => toast.error("Erro ao tentar encotrar chave");

  const optionskey = useMemo(() => {
    return [
      { label: "CNPJ", value: "cnpj" },
      { label: "CPF", value: "cpf" },
      { label: "Celular", value: "telefone" },
      { label: "Email", value: "email" },
      { label: "Aleatória", value: "evp" },
    ] as const;
  }, []);


  const {
    values,
    // handleChange,
    setFieldValue,
    // errors,
    // touched,
    // handleSubmit,
    resetForm
  } = useFormik({
    initialValues,
    onSubmit: () => { },
  });

  const filteredValuesKey = optionskey.map(
    (option) => values[option.value]
  ).filter((value) => value && value.trim() !== "");

  const payload_key = useMemo(() => {
    return {
      key:
        values.type === "email" || values.type === "evp"
          ? filteredValuesKey[0]
          : onlyNumbers(filteredValuesKey[0]),
      type: values.type,
    };
  }, [values.type, filteredValuesKey[0]]);


  const payload = useMemo(() => {
    return {
      beneficiary: Number(beneficiaryDetails?.id),
      active: true,
      key: String(isValidPixKey?.key),
      profile: Number(userProfile?.id),
      type: payload_key.type
    }
  }, [beneficiaryDetails, isValidPixKey, values])


  const fetchPixkeyvalidation = useCallback(async () => {
    setStepLoading(true);
    try {
      const response = await postCheckpixAPI(payload_key as IkeypixType);
      const data = response.data as IBankAccountInfoPix
      setIsValidPixKey(data as IBankAccountInfoPix);
      const documentNumberInput = onlyNumbers(beneficiaryDetails?.document_number || '');
      const documentNumberKeyPix = onlyNumbers(data?.document_number || "");
      if (documentNumberInput !== documentNumberKeyPix) {
        toast.error("A chave Pix adicionada não corresponde ao documento cadastrado.");
      } else {
        toast.success("Chave Pix validada e os dados cadastrados conferem.");
      }
      setpagekeyPixError(false);
      setStepLoading(false);
    } catch (err) {
      setStepLoading(false);
      setpagekeyPixError(true);
      notifyErrorkeyPix();
      console.warn(err);
    }
  }, [payload_key, values, filteredValuesKey]);


  const fetchPixKeyBeneficiaries = useCallback(async () => {
    try {
      const { data } = await getBeneficiarieskeyPIX(Number(id))
      setRenderPixKeys(data.results.sort((b, a) => b.id - a.id).reverse())
    } catch (err) {
      console.log(err)
    }
  }, [renderPixKeys, id])



  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setBeneficiaryDetails]);


  const handleChangeSelect = (e: SingleValue<SelectOption>) => {
    if (e) {
      setstateValuesPix([e]);
      setFieldValue("type", e.value);
    }
  };

  const fetchPostPixKeyBeneficiary = useCallback(
    async () => {
      setLoading(true);
      try {
        await fetchBeneficiary();
        await postBeneficiarykeyPIX(payload)
        fetchPixKeyBeneficiaries()
        setaddkeyPixModal(false);
        setLoading(false);
        resetForm()
        setStep("initial");
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        resetForm()
        notifyError();
      }
    },
    [payload, fetchBeneficiary, fetchPixKeyBeneficiaries]
  );


  useEffect(() => {
    fetchBeneficiary();
    const isDisabledButton = isValidPixKey && isValidPixKey.account_number !== "" && onlyNumbers(isValidPixKey.document_number) !== onlyNumbers(String(beneficiaryDetails?.document_number))
    if (isDisabledButton) {
      setDisabledButtonSubmit(isDisabledButton)
    }
  }, [isValidPixKey]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading={stepLoading ? "Carregando..." : "Informaçoes da Chave Pix"}
      size="md"
      open={open}
      onRequestClose={() => {
        if (step === "initial") {
          onClose();
        } else if (step === "hasKey") {
          setpagekeyPixError(false)
          resetForm()
          setDisabledButtonSubmit(false)
          setStep("initial");

        }
      }}

      primaryButtonText={step === "hasKey" ? "Concluir" : "Avançar"}
      secondaryButtonText={step === "hasKey" ? "Voltar" : "Cancelar"}

      primaryButtonDisabled={disabledButtonSubmit || isValidPixKey?.account_number === "" && pagekeyPixError}

      onRequestSubmit={async () => {
        if (step === "initial") {
          await fetchPixkeyvalidation();
          setStep("hasKey");
        } else if (step === "hasKey") {
          if (pagekeyPixError) {
            notifyError();
          } else {
            await fetchPostPixKeyBeneficiary();
          }
        }
      }}
    >
      <>
        {stepLoading && (
          <>
            <div
              style={{
                position: "absolute",
                transform: "translateY(80%) translateX(50%)",
                right: "50%",
              }}
            >
              <Loading colorSpinner="#0F62FE" />
            </div>
            <Margin mb={250} />
          </>
        )}

        {step === "initial" && !stepLoading && (
          <>
            <Margin mt={10} />
            <h4 style={{ margin: "0 auto", textAlign: "center" }}>
              Cadastro De Chave Pix
            </h4>
            <Margin mb={10} />
            <Select
              placeholder={"Tipo de Chave pix..."}
              options={optionskey}
              onChange={(ValuesChange) => {
                handleChangeSelect(ValuesChange);
                optionskey.forEach((item) => {
                  setFieldValue(item.value, "");
                });
              }}
            />

            <Margin mb={10} />
            {stateValuesPix.map((keysPix) => (
              <MaskedTextInput
                key={keysPix.value}
                mask={MaskIndex[values.type]}
                id={keysPix.value}
                name={keysPix.value}
                placeholder={`Digite Sua Chave Pix ${keysPix.label}`}
                onChange={(e) => setFieldValue(keysPix.value, e.target.value)}
              />
            ))}
            <Margin mb={150} />
          </>
        )}

        {step === "hasKey" && !pagekeyPixError && isValidPixKey && (
          <>
            <div>
              <Margin mt={10} />
              <h4
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                Informaçoes da Chave
              </h4>
              <Margin mt={20} />
              <div style={{ display: "block" }}>
                <h4>
                  <b style={{ fontWeight: "bold" }}> Nome:</b>{" "}
                  <span>{isValidPixKey.name}</span>
                </h4>
                <h4>
                  <b style={{ fontWeight: "bold" }}>CPF/CNPJ:</b>
                  <span> {formatCPFCNPJ(isValidPixKey?.document_number)}</span>
                </h4>

                <h4>
                  <b style={{ fontWeight: "bold" }}> Banco: </b>
                  <span>
                    {isValidPixKey.bank} - {isValidPixKey.bank_name}
                  </span>
                </h4>
                <h4>
                  <b style={{ fontWeight: "bold" }}>Agencia: </b>{" "}
                  <span>{isValidPixKey.agency}</span>
                </h4>
                <h4>
                  <b style={{ fontWeight: "bold" }}>Conta: </b>
                  <span>
                    {isValidPixKey.account_number}-
                    {isValidPixKey.account_number_digit}
                  </span>
                </h4>
              </div>
            </div>
          </>
        )}
        {step === "hasKey" && pagekeyPixError && (
          <div style={{ textAlign: "center" }}>
            <Margin mb={90} />
            <h3>Chave PIX não encontrada</h3>
            <Margin mb={100} />
          </div>
        )}
        <Margin mb={20} />
      </>
    </Modal >
  );
};
