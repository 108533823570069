import { Button, Column, Modal } from "@carbon/react";
import Badge from "~/components/Badge";
import Margin from "~/components/Margin";

import useAttend from "~/hooks/useAttend";

import { calculateSla, formatCPFCNPJ } from "~/services/utils";

import { Edit } from "@carbon/icons-react";
import useUser from "~/hooks/useUser";
import { useCallback, useMemo, useState } from "react";
import {
  getAttendDetailsAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";

const renderSla: { [key: string]: React.ReactNode } = {
  noPrazo: <Badge color="#CCEFDF" textColor="#00B360" label="No prazo" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Aguard. solicitante" />
  ),
  done: <Badge color="#bacbff" textColor="#1906ec" label="Atend. Finalizado" />,
  canceled: (
    <Badge color="#bacbff" textColor="#1906ec" label="Atend. Cancelado" />
  ),
  atrasado: <Badge label="Atrasado" textColor="#ff0000" color="#f9c6c6" />,
};

const LateralDetailsAttends = () => {
  const {
    attendDetails,
    subAttends,
    // setOpenUpdatedAttendAreaModal,
    subAttendDetails,
    setOpenUpdatedOwnerAttendModal,
    // setOpenUpdatedAreaTypeModal,
    attendLog,
    attendParentData,
    setAttendDetails,
  } = useAttend();

  const { userProfile } = useUser();

  const isSolicitant = useMemo(() => {
    return attendDetails?.client.id === userProfile?.id;
  }, [attendDetails, userProfile]);

  const _fetchAttends = useCallback(async () => {
    if (attendDetails) {
      try {
        const { data } = await getAttendDetailsAPI(Number(attendDetails?.id));

        setAttendDetails(data);
      } catch (err: unknown) {
        console.error(err);
      }
    }
  }, [attendDetails, setAttendDetails]);

  const fetchSendNewStatus = useCallback(
    async (id: number) => {
      try {
        await patchAttendDetailsAPI(Number(attendDetails?.id), {
          status: id,
        });
        _fetchAttends();
        setOpenCancelAttendModal(false);
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [attendDetails, _fetchAttends]
  );

  const fetchSendNewStatusSubAttends = useCallback(
    async (id: number) => {
      try {
        await Promise.all(
          subAttends.map(async (subAttend) => {
            await patchAttendDetailsAPI(Number(subAttend.id), {
              status: id,
            });
          })
        );
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [subAttends]
  );

  const [openCancelAttendModal, setOpenCancelAttendModal] =
    useState<boolean>(false);

  return (
    <Column span={4} sm={{}}>
      <div
        style={{
          width: "100%",
          flex: 1,
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#f7f7f7",
          position: "relative",
          top: 20,
        }}
      >
        <Margin mb={20} />

        <div
          style={{
            marginLeft: "50px",
            width: "100%",
          }}
        >
          {subAttendDetails !== undefined && (
            <div>
              <h6 style={{ fontWeight: "bold" }}>Vinculado ao</h6>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <a
                    href={`/app/attends/${attendParentData?.id}`}
                    style={{ cursor: "pointer" }}
                  >
                    #{attendParentData?.id} -{" "}
                    {attendParentData?.type.name ?? "-"}
                  </a>
                </div>
              </div>
              <Margin mb={20} />
            </div>
          )}
          <div>
            <h6 style={{ fontWeight: "bold", marginBottom: "15px" }}>Status</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Margin mb={isSolicitant ? 10 : -10} />

                {attendDetails?.status.name ? (
                  <Badge
                    label={
                      isSolicitant && attendDetails.status.id === 3
                        ? "Alocado"
                        : attendDetails.status.name
                    }
                    color={
                      isSolicitant && attendDetails.status.id === 3
                        ? "#e3e3e3"
                        : attendDetails.status?.color
                    }
                    textColor="#000000"
                  />
                ) : (
                  "Não encontrado"
                )}
              </div>
            </div>
          </div>
          <Margin mb={10} />
          {isSolicitant && <Margin mb={20} />}
          <div>
            <h6 style={{ fontWeight: "bold" }}>Solicitante</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4 style={{ fontWeight: "600" }}>
                  {attendDetails?.client?.raw_data?.company_name
                    ? attendDetails?.client?.raw_data?.company_name
                    : !attendDetails?.client?.raw_data?.company_name &&
                      (attendDetails?.client.type.includes("internal") ||
                        attendDetails?.client.type.includes("area_manager") ||
                        attendDetails?.client.type.includes("tool_manager")) &&
                      "Banvox"}
                </h4>
                {attendDetails?.client?.raw_data?.company_document_number && (
                  <h5 style={{}}>
                    {formatCPFCNPJ(
                      attendDetails?.client?.raw_data?.company_document_number
                    )}
                  </h5>
                )}

                <h4>{attendDetails?.client?.name}</h4>
              </div>
            </div>
          </div>

          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Fundo</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4> {attendDetails?.fund?.raw_data?.short_name}</h4>
              </div>
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Responsável</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Margin mb={isSolicitant ? 5 : -5} />
                <h4> {attendDetails?.owner?.name ?? "-"}</h4>
              </div>
              {!isSolicitant &&
                userProfile?.type.includes("area_manager") &&
                attendDetails?.status.name !== "Finalizado" &&
                attendDetails?.status.name !== "Cancelado" && (
                  <Button
                    kind="ghost"
                    hasIconOnly
                    iconDescription="Atribuir responsável"
                    renderIcon={Edit}
                    onClick={() => setOpenUpdatedOwnerAttendModal(true)}
                    id="filter"
                  />
                )}
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Área</h6>

            <Margin mb={isSolicitant ? 5 : -5} />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4>{attendLog?.actual_area?.name ?? "-"}</h4>
              </div>
              {/* {!isSolicitant && (
                <Button
                  kind="ghost"
                  hasIconOnly
                  iconDescription="Trocar área"
                  renderIcon={Edit}
                  id="filter"
                  onClick={() => setOpenUpdatedAttendAreaModal(true)}
                />
              )} */}
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>SLA</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%", marginTop: "8px" }}>
                {attendDetails?.status?.slug === "done" ? (
                  renderSla["done"]
                ) : attendDetails?.status?.slug === "canceled" ? (
                  renderSla["canceled"]
                ) : attendDetails?.status?.slug === "return_to_client" ? (
                  renderSla["return_to_client"]
                ) : (
                  <div>
                    {" "}
                    {
                      renderSla[
                        calculateSla(
                          attendDetails?.type?.sla ?? 0,
                          attendDetails?.created_on ?? "",
                          attendDetails?.type.sla_type
                        ).status
                      ]
                    }
                    <h4>
                      {
                        calculateSla(
                          attendDetails?.type?.sla ?? 0,
                          attendDetails?.created_on ?? "",
                          attendDetails?.type.sla_type
                        ).formattedDuration
                      }
                    </h4>
                  </div>
                )}
              </div>
            </div>
            <Margin mb={20} />
            {(isSolicitant || userProfile?.type?.includes("area_manager")) &&
              attendDetails?.status.name !== "Cancelado" &&
              attendDetails?.status.name !== "Finalizado" && (
                <div>
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      setOpenCancelAttendModal(true);
                    }}
                    kind="danger--tertiary"
                  >
                    Cancelar atendimento
                  </Button>
                </div>
              )}
          </div>
          <Margin mb={20} />
        </div>
      </div>
      <Modal
        modalHeading={`Cancelar atendimento - ${attendDetails?.type.name}`}
        size="sm"
        open={openCancelAttendModal}
        onRequestClose={() => setOpenCancelAttendModal(false)}
        primaryButtonText="Sim, quero cancelar o atendimento"
        secondaryButtonText="Não, desejo voltar"
        onRequestSubmit={() => {
          fetchSendNewStatus(5);
          fetchSendNewStatusSubAttends(5);
        }}
        style={{ zIndex: 2 }}
      >
        <div>
          <Margin mb={50} />
          <h3 style={{ textAlign: "center" }}>
            {" "}
            Atenção, você realmente deseja cancelar este atendimento? Esta ação
            é irreversível.{" "}
          </h3>
          <Margin mb={150} />
        </div>
      </Modal>
    </Column>
  );
};
export default LateralDetailsAttends;
