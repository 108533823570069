import { useFormik } from 'formik';
import { Input, Modal, Notification } from 'prosperita-dumbo-react'
import { useCallback, useState } from 'react';
import useLoading from '~/hooks/useLoading';
import useUser from '~/hooks/useUser';
import { postForgotPassword } from '~/services/api/password';
import { ConfirmedForgotPasswordModal } from './ConfirmedForgotPasswordModal';
import * as Yup from "yup";
import Margin from '~/components/Margin';


type INotification = {
    status: "success" | "warning" | "info" | "error";
    message: string;
    title: string;
    active: boolean;
}

type ImodalNewPassword = {
    open: boolean;
    onClose?: () => void;
    onSubmit?: () => void
}
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Email inválido") // Mensagem de erro para email inválido
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email deve ser um formato válido") // Regex para validar o formato do email
        .required("Digite o Email"), // Mensagem de erro se o campo for vazio
});
export const ForgotPasswordModal = (props: ImodalNewPassword) => {

    const { onClose, open } = props

    const {
        setIsForgotPasswordModalOpen,
        isConfirmSendPasswordModalOpen,
        setIsConfirmSendPasswordModalOpen,
        setverifiedEmail
    } = useUser()

    const { setLoading } = useLoading();

    const [notificationDetails, setNotificationDetails] = useState<INotification>({
        status: "info",
        message: "",
        title: "",
        active: false
    })

    const { values, resetForm, handleChange, errors } =
        useFormik({
            validationSchema,
            initialValues: {
                email: ""
            },
            onSubmit: () => {
            },
        });

    const fetchForgotPassword = useCallback(async () => {
        setLoading(true);
        try {
            await postForgotPassword({ user: values.email })
            setLoading(false);
            setIsConfirmSendPasswordModalOpen(false)
            setNotificationDetails({
                status: "success",
                message: "Sua senha foi redefinida com sucesso e enviada para o e-mail do usuário",
                title: 'Redefinição de Senha Bem-Sucedida',
                active: true
            })
            setverifiedEmail("")
            resetForm()
            setTimeout(() => {
                setNotificationDetails((prev) => ({
                    ...prev,
                    active: false
                }));
            }, 5000)
        } catch (err: unknown) {
            console.error(err);
            setLoading(false);
            setIsConfirmSendPasswordModalOpen(false)
            setverifiedEmail("")
            resetForm()
            setNotificationDetails({
                status: "error",
                message: 'Ocorreu um erro ao tentar redefinir a senha. Por favor, verifique os dados e tente novamente.',
                title: "Erro na Redefinição de Senha",
                active: true
            })
            setTimeout(() => {
                setNotificationDetails((prev) => ({
                    ...prev,
                    active: false
                }));
            }, 5000)
        }
    }, [values])


    const handleSubmitModalEmail = () => {
        setIsConfirmSendPasswordModalOpen(true)
        setIsForgotPasswordModalOpen(false)
        setverifiedEmail(values.email)
    }


    const handlevalues = useCallback(() => {
        setNotificationDetails({
            status: "warning",
            message: 'Para prosseguir com a redefinição da senha, por favor, digite o seu e-mail cadastrado.',
            title: "Informe Seu E-mail",
            active: true
        })
        setTimeout(() => {
            setNotificationDetails((prev) => ({
                ...prev,
                active: false
            }));
        }, 5000)
    }, [])

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                height='325px'
                overflow
                buttons={[
                    {
                        label: "Cancelar",
                        onClick: () => {
                            setIsForgotPasswordModalOpen(false)
                            resetForm()
                        },
                        kind: "ghost"
                    },
                    {
                        label: "Avançar",
                        onClick: () => values.email.length > 10 && !errors.email ? handleSubmitModalEmail() : handlevalues(),
                        kind: "primary"
                    }
                ]}
                label={<h3 className='text-center font-[400] pt-2'>Esqueci Minha Senha</h3>}>
                <div className='w-[95%] mx-auto mt-2'>
                    <p className='text-[18px] mt-0 text-center'>Você receberá uma senha provisória por e-mail. Após entrar no Portal Banvox, poderá redefinir sua senha para uma de sua preferência</p>
                    <Margin mt={20} />
                    <Input
                        label='Digite seu Email'
                        type='text'
                        size='large'
                        placeholder='Email'
                        name='email'
                        noRadius
                        maxLength={60}
                        value={values.email}
                        onChange={handleChange}
                    />
                    <p className="text-red-500 text-sm m-0 p-0">{errors.email && "* " + errors.email}</p>
                </div>
            </Modal >
            <ConfirmedForgotPasswordModal
                open={isConfirmSendPasswordModalOpen}
                onClose={() => {
                    setIsConfirmSendPasswordModalOpen(false)
                }}
                onSubmit={() => fetchForgotPassword()}
            />
            <Notification
                zindex={notificationDetails.status === "warning" ? "15" : "8"}
                top={"40px"}
                right='20px'
                onClose={() =>
                    setNotificationDetails((prev) => ({
                        ...prev,
                        active: false
                    }))}
                noRadius
                status={notificationDetails.status}
                actived={notificationDetails.active}
                title={notificationDetails.title}
                message={notificationDetails.message}
            >
            </Notification >
        </>
    )
}
