import { Modal, RadioButton, RadioButtonGroup, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";

import useStatus from "~/hooks/useStatus";
import {
  getStatusAPI,
  ISendUpdateStatus,
  putStatusAPI,
} from "~/services/api/status";
import Label from "~/components/Label";

type Values = {
  name: string;
  color: string | undefined;
  active_status: boolean | undefined;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const EditStatusModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { detailsStatus, setOpenEditStatusModal, setStatus } = useStatus();

  const notifySuccess = () => toast.success("Status atualizado com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível atualizar o Status. Tente novamente mais tarde."
    );

  const initialValues: Values = {
    name: detailsStatus?.name || "",
    color: detailsStatus?.color,
    active_status: detailsStatus?.active, // Ensure it's a boolean
  };

  const { handleSubmit, values, handleBlur, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const [selectedRadio, setSelectedRadio] = useState<string>("");

  const payload: ISendUpdateStatus = useMemo(() => {
    return {
      name: values.name ?? "",
      color: values.color ?? "",
      active: selectedRadio === "active" ? true : false,
    };
  }, [values, selectedRadio]);

  const fetchStatus = useCallback(async () => {
    try {
      const { data } = await getStatusAPI();
      setStatus(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setStatus]);

  const handleChangeRadio = (value: unknown) => {
    setSelectedRadio(String(value));
  };

  const fetchUpdateStatus = async (payload: ISendUpdateStatus) => {
    setLoading(true);
    try {
      if (detailsStatus) {
        await putStatusAPI(payload, Number(detailsStatus?.id));
        setOpenEditStatusModal(false);

        await fetchStatus();

        setLoading(false);
        notifySuccess();
      }
    } catch (error) {
      console.error("Erro ao excluir", error);
      setLoading(false);
      notifyError();
    }
  };

  useEffect(() => {
    if (detailsStatus) {
      setFieldValue("name", detailsStatus?.name);
      setFieldValue("active_status", detailsStatus?.active);
      setSelectedRadio(detailsStatus?.active ? "active" : "inactive");
      setFieldValue("color", String(detailsStatus.color));
    }
  }, [detailsStatus, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 6 }}
        modalHeading={`Editar Status - ${detailsStatus?.name}`}
        size="sm"
        open={open}
        primaryButtonDisabled={values.name === ""}
        onRequestClose={() => {
          onClose();
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchUpdateStatus(payload);
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Nome"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={20} />

        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <label htmlFor="colorPicker" className="bx--label">
            Cor do Badge
          </label>
          <input
            type="color"
            id="color"
            value={values.color}
            onChange={handleChange}
            style={{
              height: "3rem",
              width: "3rem",
              border: "none",
              cursor: "pointer",
            }}
          />
          <TextInput
            id="color"
            labelText="Cor selecionada (hex):"
            value={values.color}
            onChange={handleChange}
            placeholder="#000000"
          />
        </div>

        <Margin mb={24} />
        <Label text="Status" />

        <Margin mb={6} />
        <RadioButtonGroup
          name="radio-group"
          valueSelected={selectedRadio}
          onChange={handleChangeRadio}
        >
          <RadioButton
            labelText="Inativo"
            value="inactive"
            id="edit-1"
            style={{ display: "block" }}
          />
          <RadioButton
            labelText="Ativo"
            value="active"
            id="edit-2"
            style={{ display: "block" }}
          />
        </RadioButtonGroup>
        <Margin mb={24} />
      </Modal>
    </form>
  );
};

export default EditStatusModal;
