import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  // Tile,
  Pagination,
} from "@carbon/react";

import { TrashCan, Add, Save, Download, Renew } from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { getAttendAPI } from "~/services/api/attend";

import { useFormik } from "formik";
import Padding from "~/components/Padding";
import FiltersModal from "./FiltersModal";
import useAttend from "~/hooks/useAttend";
// import { format } from "date-fns";
// import styled from "styled-components";

import Badge from "~/components/Badge";
import { permissions } from "~/services/permissions";
import { calculateSla } from "~/services/utils";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import useStatus from "~/hooks/useStatus";
import { getStatusAPI } from "~/services/api/status";
import useUser from "~/hooks/useUser";

// const TileContainer = styled.div`
//   display: flex;
//   gap: 15px;
// `;

const tableHeadersAttend: string[] = [
  "Nº",
  "Status",
  "Solicitante",
  "Fundo",
  "Tipo",
  "Atendente",
  "SLA",
  // "Criado em",
];

const renderSla: { [key: string]: React.ReactNode } = {
  noPrazo: <Badge color="#CCEFDF" textColor="#00B360" label="No prazo" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Aguard. solicitante" />
  ),
  done: <Badge color="#bacbff" textColor="#1906ec" label="Finalizado" />,
  canceled: <Badge textColor="#ff0000" color="#f9c6c6" label="Cancelado" />,
  atrasado: <Badge label="Atrasado" textColor="#ff0000" color="#f9c6c6" />,
};

const Attends = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { status: statusData, setStatus } = useStatus();
  const { attends, setAttends } = useAttend();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAttends, setFilteredAttends] = useState(attends);
  const { userProfile } = useUser();

  console.debug(filteredAttends, searchTerm);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const canReadAttends = permissions.includes("can_read_attends");
  // const canDeleteAttends = permissions.includes("can_delete_attends")
  // const canEditAttends = permissions.includes("can_edit_attends")
  const canCreatAttends = permissions.includes("can_create_attends");

  const { values } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: { search: string }) => {
      console.log(values);
    },
  });

  // interface StatusCounts {
  //   created?: number;
  //   done?: number;
  //   in_progress?: number;
  //   opened?: number;
  //   canceled?: number;
  //   return_to_client?: number;
  //   [key: string]: number | undefined;
  // }

  // const totalAttends = useMemo<StatusCounts>(() => {
  //   const statusCounts: StatusCounts = attends?.reduce((acc, attend) => {
  //     const status = attend?.status?.slug;
  //     if (status) {
  //       acc[status] = (acc[status] || 0) + 1;
  //     }
  //     return acc;
  //   }, {} as StatusCounts);

  //   return statusCounts || {};
  // }, [attends]);

  const paginatedAttends = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return attends.slice(start, end);
  }, [currentPage, itemsPerPage, attends]);

  const paginatedAttendsByStatus = (statusName: string) => {
    const filteredAttends = attends.filter((attend) => {
      if (statusName === "Alocado") {
        return (
          attend.status.name === statusName ||
          (attend.status.id === 3 && attend.client.id === userProfile?.id)
        );
      }

      return (
        attend.status.name === statusName &&
        !(attend.status.id === 3 && attend.client.id === userProfile?.id)
      );
    });

    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return filteredAttends.slice(start, end);
  };

  const paginatedAttendsByClient = (statusName: string) => {
    const filteredAttends = attends.filter(
      (attend) =>
        attend.status.name === statusName ||
        (attend.status.id === 3 && statusName === "Alocado")
    );

    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return filteredAttends.slice(start, end);
  };

  // const renderTiles: { label: string; total: number }[] = [
  //   { label: "Criados", total: totalAttends?.created ?? 0 },
  //   { label: "Em aberto ", total: totalAttends?.opened ?? 0 },
  //   { label: "Em andamento", total: totalAttends?.in_progress ?? 0 },
  //   {
  //     label: "Aguardando solicitante",
  //     total: totalAttends?.return_to_client ?? 0,
  //   },
  //   { label: "Finalizados", total: totalAttends?.done ?? 0 },
  //   { label: "Cancelados", total: totalAttends?.canceled ?? 0 },
  // ];

  // const filterAttends = () => {
  //   const filtered = attends.filter((profile) =>
  //     profile.subject.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredAttends(filtered);
  // };

  const fetchAttends = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendAPI();
      setAttends(data.results.reverse().sort((a, b) => b.id - a.id));

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setAttends, setLoading]);

  const fetchStatus = useCallback(async () => {
    try {
      const { data } = await getStatusAPI();
      setStatus(data.results.sort((a, b) => a.name.localeCompare(b.name)));

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  // const deleteAttend = async () => {
  //   try {
  //     await deleteAttendDetailsAPI(33);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    fetchAttends();
    fetchStatus();
  }, [fetchAttends]);

  // useEffect(() => {
  //   filterAttends();
  // }, [values.search]);

  useEffect(() => {
    setSearchTerm(values.search);
    if (values.search === "") {
      setFilteredAttends([]);
    }
  }, [values.search]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Atendimentos</h1>
        <Margin mt={34} />{" "}
        {/* <TileContainer>
          {canReadAttends &&
            renderTiles.map((tile, index) => {
              return (
                <Tile style={{ flex: 1 }} key={String(index)}>
                  <div>
                    <p>{tile.label}</p>
                    <h2>{tile.total}</h2>
                  </div>
                </Tile>
              );
            })}
        </TileContainer> */}
        <Margin mt={20} />
        <Tabs
          defaultActiveKey="1"
          tabBarStyle={{
            margin: 0,
            borderBottom: "none",
          }}
        >
          <TabPane tab="Todos" key="0">
            <TableContainer>
              <Margin mb={20} />
              <TableToolbar>
                {canReadAttends && (
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      fetchAttends();
                    }}
                    renderIcon={Renew}
                    kind="secondary"
                  >
                    Atualizar
                  </Button>
                )}
                {!canReadAttends && (
                  <div
                    style={{
                      height: "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h4 style={{ position: "relative", left: "45%" }}>
                      Você não possui permissão para visualizar os atendimentos
                    </h4>
                  </div>
                )}
                <TableBatchActions onCancel={console.log} totalSelected={0}>
                  <TableBatchAction
                    tabIndex={0}
                    renderIcon={TrashCan}
                    onClick={console.log}
                  >
                    Delete
                  </TableBatchAction>
                  <TableBatchAction
                    hasIconOnly
                    iconDescription="Add"
                    tabIndex={0}
                    renderIcon={Add}
                    onClick={console.log}
                  >
                    Delete
                  </TableBatchAction>
                  <TableBatchAction
                    hasIconOnly
                    iconDescription="Save"
                    tabIndex={0}
                    renderIcon={Save}
                    onClick={console.log}
                  >
                    Save
                  </TableBatchAction>
                  <TableBatchAction
                    tabIndex={0}
                    renderIcon={Download}
                    onClick={console.log}
                  >
                    Download
                  </TableBatchAction>
                </TableBatchActions>
                <TableToolbarContent>
                  {/* <TableToolbarSearch
                tabIndex={0}
                onChange={handleChange}
                value={values.search}
                id="search"
                placeholder="Pesquisar"
              /> */}

                  {/* {user.type === "tool_manager" && (
                <Tooltip defaultOpen label="Exportar relatório" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Report}
                    id="filter"
                  />
                </Tooltip>
              )}
              <Tooltip defaultOpen label="Filtrar" align="top">
                <Button
                  kind="ghost"
                  hasIconOnly
                  renderIcon={Filter}
                  id="filter"
                  onClick={() => setOpenModal(true)}
                />
              </Tooltip> */}
                  {canCreatAttends && (
                    <Button
                      tabIndex={0}
                      onClick={() => {
                        navigate("/app/attends/new");
                      }}
                      kind="primary"
                    >
                      Novo atendimento
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>
              {canReadAttends && (
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                  ariaLabel={""}
                /> */}
                      {tableHeadersAttend.map((columnTitle, index) => {
                        return (
                          <TableHeader key={String(index)}>
                            {columnTitle}
                          </TableHeader>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedAttends?.length === 0 ? (
                      <TableCell colSpan={tableHeadersAttend.length + 1}>
                        <Padding pt={24} pb={24} pl={12}>
                          <div
                            style={{
                              height: "300px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h4>Nenhum atendimento até o momento.</h4>
                          </div>
                        </Padding>
                      </TableCell>
                    ) : paginatedAttends.length > 0 ? (
                      paginatedAttends?.map((attend, index) => (
                        <TableRow
                          key={String(index)}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/app/attends/${attend.id}`)}
                        >
                          {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                          <TableCell>#{attend.id}</TableCell>
                          <TableCell>
                            {attend?.status.name ? (
                              <div>
                                {userProfile?.type.includes("client") ||
                                userProfile?.type.includes("manager") ? (
                                  <Badge
                                    label={
                                      attend.status.id === 3
                                        ? "Alocado"
                                        : attend.status.name
                                    }
                                    color={
                                      attend.status.id === 3
                                        ? "#e3e3e3"
                                        : attend.status?.color
                                    }
                                    textColor="#000000"
                                  />
                                ) : attend.client.id === userProfile?.id &&
                                  attend.status.id === 3 ? (
                                  <Badge
                                    label={"Alocado"}
                                    color={"#e3e3e3"}
                                    textColor="#000000"
                                  />
                                ) : (
                                  <Badge
                                    label={attend?.status.name}
                                    color={attend?.status.color}
                                    textColor="#000000"
                                  />
                                )}
                              </div>
                            ) : (
                              "Não encontrado"
                            )}
                          </TableCell>

                          {/* <TableCell>{attend.area}</TableCell>
                  <TableCell>{attend.area_manager}</TableCell> */}
                          <TableCell>
                            <p style={{ fontWeight: "600" }}>
                              {" "}
                              {attend?.client?.company?.name
                                ? attend?.client?.company?.name
                                : !attend?.client?.company?.name &&
                                  !attend?.client?.type.includes("manager") &&
                                  !attend?.client?.type.includes("client") &&
                                  "BANVOX"}
                            </p>
                            <Margin mt={-15} />
                            <p>
                              <b>{attend.client?.name}</b>
                            </p>
                          </TableCell>
                          <TableCell>
                            {attend?.fund?.raw_data?.short_name ?? "-"}
                          </TableCell>
                          <TableCell>{attend?.type?.name}</TableCell>

                          <TableCell>{attend?.owner?.name ?? "-"}</TableCell>

                          <TableCell>
                            <div style={{ width: "100%", minWidth: "90px" }}>
                              {attend?.status?.slug === "done" ? (
                                renderSla["done"]
                              ) : attend?.status?.slug === "canceled" ? (
                                renderSla["canceled"]
                              ) : (
                                <div style={{ marginTop: "5px" }}>
                                  {" "}
                                  {
                                    renderSla[
                                      calculateSla(
                                        attend?.type?.sla ?? 0,
                                        attend?.created_on ?? "",
                                        attend.type.sla_type
                                      ).status
                                    ]
                                  }
                                  <h6>
                                    {
                                      calculateSla(
                                        attend?.type?.sla ?? 0,
                                        attend?.created_on ?? "",
                                        attend.type.sla_type
                                      ).formattedDuration
                                    }
                                  </h6>
                                </div>
                              )}
                            </div>
                          </TableCell>
                          {/* <TableCell>
                        {format(attend.created_on, "yyyy-MM-dd")}
                      </TableCell> */}
                        </TableRow>
                      ))
                    ) : (
                      paginatedAttends?.map((attend, index) => (
                        <TableRow
                          key={String(index)}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/app/attends/${attend.id}`)}
                        >
                          {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                          <TableCell>#{attend.id}</TableCell>
                          <TableCell>
                            {attend?.status.name ? (
                              <Badge
                                label={attend.status.name}
                                color={attend.status?.color}
                                textColor="#000000"
                              />
                            ) : (
                              "Não encontrado"
                            )}
                          </TableCell>
                          {/* <TableCell>{attend.area}</TableCell>
                    <TableCell>{attend.area_manager}</TableCell> */}
                          <TableCell>
                            <p>
                              {" "}
                              <b>{attend.client.name}</b>
                            </p>
                          </TableCell>
                          <TableCell>
                            {attend?.fund?.raw_data?.short_name ?? "-"}
                          </TableCell>
                          <TableCell>{attend?.type?.name}</TableCell>

                          <TableCell>{attend?.owner?.name ?? "-"}</TableCell>

                          <TableCell>{attend?.type?.sla}h</TableCell>
                          {/* <TableCell>
                        {format(attend.created_on, "dd-MM-yyyy")}
                      </TableCell> */}
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              )}
              <Pagination
                totalItems={attends.length}
                pageSize={itemsPerPage}
                pageSizes={[5, 10, 20, 30, 40, 50]}
                onChange={({ page, pageSize }) => {
                  setCurrentPage(page);
                  setItemsPerPage(pageSize);
                }}
                page={currentPage}
                size="md"
              />
            </TableContainer>
          </TabPane>
          {(userProfile?.type.includes("tool_manager") ||
            userProfile?.type.includes("area_manager") ||
            userProfile?.type.includes("internal")) &&
            statusData
              ?.filter((status) => status.active)
              .map((status) => {
                const paginatedAttends = paginatedAttendsByStatus(status.name);
                return (
                  <TabPane tab={status.name} key={status.name}>
                    <TableContainer>
                      <Margin mb={20} />
                      <TableToolbar>
                        {canReadAttends && (
                          <Button
                            tabIndex={0}
                            onClick={() => fetchAttends()}
                            renderIcon={Renew}
                            kind="secondary"
                          >
                            Atualizar
                          </Button>
                        )}
                        {!canReadAttends && (
                          <div
                            style={{
                              height: "400px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <h4 style={{ position: "relative", left: "45%" }}>
                              Você não possui permissão para visualizar os
                              atendimentos
                            </h4>
                          </div>
                        )}
                        {/* ... Resto do código do TableToolbar ... */}
                      </TableToolbar>
                      {canReadAttends && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              {tableHeadersAttend.map((columnTitle, index) => (
                                <TableHeader key={String(index)}>
                                  {columnTitle}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedAttends.length === 0 ? (
                              <TableCell
                                colSpan={tableHeadersAttend.length + 1}
                              >
                                <Padding pt={24} pb={24} pl={12}>
                                  <div
                                    style={{
                                      height: "300px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4>
                                      Nenhum atendimento com esse status até o
                                      momento.
                                    </h4>
                                  </div>
                                </Padding>
                              </TableCell>
                            ) : (
                              paginatedAttends.map((attend, index) => (
                                <TableRow
                                  key={String(index)}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/app/attends/${attend.id}`)
                                  }
                                >
                                  <TableCell>#{attend.id}</TableCell>
                                  <TableCell>
                                    {attend.client.id === userProfile?.id &&
                                    attend.status.id === 3 ? (
                                      <Badge
                                        label={"Alocado"}
                                        color={"#e3e3e3"}
                                        textColor="#000000"
                                      />
                                    ) : (
                                      <Badge
                                        label={attend?.status.name}
                                        color={attend?.status.color}
                                        textColor="#000000"
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <p style={{ fontWeight: "600" }}>
                                      {" "}
                                      {attend?.client?.company?.name
                                        ? attend?.client?.company?.name
                                        : !attend?.client?.company?.name &&
                                          !attend?.client?.type.includes(
                                            "manager"
                                          ) &&
                                          !attend?.client?.type.includes(
                                            "client"
                                          ) &&
                                          "BANVOX"}
                                    </p>
                                    <Margin mt={-15} />
                                    <p>
                                      <b>{attend.client?.name}</b>
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    {attend?.fund?.raw_data?.short_name ?? "-"}
                                  </TableCell>
                                  <TableCell>{attend?.type?.name}</TableCell>

                                  <TableCell>
                                    {attend?.owner?.name ?? "-"}
                                  </TableCell>

                                  <TableCell>
                                    <div
                                      style={{
                                        width: "100%",
                                        minWidth: "90px",
                                      }}
                                    >
                                      {attend?.status?.slug === "done" ? (
                                        renderSla["done"]
                                      ) : attend?.status?.slug ===
                                        "canceled" ? (
                                        renderSla["canceled"]
                                      ) : (
                                        <div style={{ marginTop: "5px" }}>
                                          {" "}
                                          {
                                            renderSla[
                                              calculateSla(
                                                attend?.type?.sla ?? 0,
                                                attend?.created_on ?? "",
                                                attend.type.sla_type
                                              ).status
                                            ]
                                          }
                                          <h6>
                                            {
                                              calculateSla(
                                                attend?.type?.sla ?? 0,
                                                attend?.created_on ?? "",
                                                attend.type.sla_type
                                              ).formattedDuration
                                            }
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </TabPane>
                );
              })}

          {(userProfile?.type.includes("client") ||
            userProfile?.type.includes("manager")) &&
            statusData
              ?.filter((status) => status.active && status.id !== 3)
              .map((status) => {
                const paginatedAttends = paginatedAttendsByClient(status.name);
                return (
                  <TabPane tab={status.name} key={status.name}>
                    <TableContainer>
                      <Margin mb={20} />
                      <TableToolbar>
                        {canReadAttends && (
                          <Button
                            tabIndex={0}
                            onClick={() => fetchAttends()}
                            renderIcon={Renew}
                            kind="secondary"
                          >
                            Atualizar
                          </Button>
                        )}
                        {!canReadAttends && (
                          <div
                            style={{
                              height: "400px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <h4 style={{ position: "relative", left: "45%" }}>
                              Você não possui permissão para visualizar os
                              atendimentos
                            </h4>
                          </div>
                        )}
                        {/* ... Resto do código do TableToolbar ... */}
                      </TableToolbar>
                      {canReadAttends && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              {tableHeadersAttend.map((columnTitle, index) => (
                                <TableHeader key={String(index)}>
                                  {columnTitle}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedAttends.length === 0 ? (
                              <TableCell
                                colSpan={tableHeadersAttend.length + 1}
                              >
                                <Padding pt={24} pb={24} pl={12}>
                                  <div
                                    style={{
                                      height: "300px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4>
                                      Nenhum atendimento com esse status até o
                                      momento.
                                    </h4>
                                  </div>
                                </Padding>
                              </TableCell>
                            ) : (
                              paginatedAttends.map((attend, index) => (
                                <TableRow
                                  key={String(index)}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/app/attends/${attend.id}`)
                                  }
                                >
                                  <TableCell>#{attend.id}</TableCell>
                                  <TableCell>
                                    <Badge
                                      label={
                                        attend.status.id === 3
                                          ? "Alocado"
                                          : attend.status.name
                                      }
                                      color={
                                        attend.status.id === 3
                                          ? "#e3e3e3"
                                          : attend.status?.color
                                      }
                                      textColor="#000000"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <p style={{ fontWeight: "600" }}>
                                      {" "}
                                      {attend?.client?.company?.name
                                        ? attend?.client?.company?.name
                                        : !attend?.client?.company?.name &&
                                          !attend?.client?.type.includes(
                                            "manager"
                                          ) &&
                                          !attend?.client?.type.includes(
                                            "client"
                                          ) &&
                                          "BANVOX"}
                                    </p>
                                    <Margin mt={-15} />
                                    <p>
                                      <b>{attend.client?.name}</b>
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    {attend?.fund?.raw_data?.short_name ?? "-"}
                                  </TableCell>
                                  <TableCell>{attend?.type?.name}</TableCell>

                                  <TableCell>
                                    {attend?.owner?.name ?? "-"}
                                  </TableCell>

                                  <TableCell>
                                    <div
                                      style={{
                                        width: "100%",
                                        minWidth: "90px",
                                      }}
                                    >
                                      {attend?.status?.slug === "done" ? (
                                        renderSla["done"]
                                      ) : attend?.status?.slug ===
                                        "canceled" ? (
                                        renderSla["canceled"]
                                      ) : (
                                        <div style={{ marginTop: "5px" }}>
                                          {" "}
                                          {
                                            renderSla[
                                              calculateSla(
                                                attend?.type?.sla ?? 0,
                                                attend?.created_on ?? "",
                                                attend.type.sla_type
                                              ).status
                                            ]
                                          }
                                          <h6>
                                            {
                                              calculateSla(
                                                attend?.type?.sla ?? 0,
                                                attend?.created_on ?? "",
                                                attend.type.sla_type
                                              ).formattedDuration
                                            }
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </TabPane>
                );
              })}
        </Tabs>
      </Column>
      <FiltersModal onClose={() => setOpenModal(false)} open={openModal} />
    </Grid>
  );
};

export default Attends;
