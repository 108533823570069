import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
// import useUser from "~/hooks/useUser";

import { useFormik } from "formik";
import {
    getBeneficiariesAPI,
    getBeneficiariesBankAccount,
    getBeneficiaryAPI,
    IbeneficiaryBankAccount,
    patchBeneficiaryBankAccount,
} from "~/services/api/beneficiaries";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { useParams } from "react-router-dom";
// import styled from "styled-components";
import * as Yup from "yup";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

type IAccount = {
    account_number: string;
    account_number_digit: string;
    agency: string;
    bank: string;
    bank_name: string;
};

type ImescleOptionsAccountPix = IAccount;

// const StyledTextInput = styled(TextInput)`
//   & input[type="number"]::-webkit-inner-spin-button,
//   & input[type="number"]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }

//   & input[type="number"] {
//     -moz-appearance: textfield;
//   }
// `;

const ReproveAccountModalBeneficiary = ({
    onClose,
    open,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { setLoading } = useLoading();
    // const { userProfile } = useUser();
    const { setBanks } = useBanks();
    const { id } = useParams();

    const {
        setBeneficiaries,
        setBeneficiaryDetails,
        setappReproveAccountBeneficiaryModal,
        accountBeneficiary,
        beneficiaryAccounts,
        setAccountBeneficiary
    } = useBeneficiary();


    const notifySuccess = () =>
        toast.success("Conta Reprovada com sucesso!");
    const notifyError = () =>
        toast.error(
            "não foi possivel Reprovar a Conta. Tente novamente mais tarde."
        );

    const fetchBanks = useCallback(async () => {
        try {
            const { data } = await getBanks();
            setBanks(data.results);
        } catch (err: unknown) {
            console.error(err);
        }
    }, []);

    const validationSchema = Yup.object({
        bank: Yup.string().required("O banco é obrigatório"),
        agency: Yup.string()
            .matches(/^\d+$/, "A agência deve conter apenas números")
            .required("A agência é obrigatória"),
        account_number: Yup.string()
            .matches(/^\d+$/, "A conta deve conter apenas números")
            .required("O número da conta é obrigatório"),
    });
    const InitialValues: Omit<ImescleOptionsAccountPix, "account_number_digit"> =
    {
        account_number: "",
        agency: "",
        bank: "",
        bank_name: "",
    };
    const { resetForm } = useFormik({
        initialValues: InitialValues,
        validationSchema,
        onSubmit: () => console.log(""),
    });


    const fetchBeneficiaries = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await getBeneficiariesAPI();
            setBeneficiaries(data.results);
            setLoading(false);
        } catch (err: unknown) {
            console.error(err);
            setLoading(false);
        }
    }, []);

    const fetchBeneficiary = useCallback(async () => {
        try {
            const { data } = await getBeneficiaryAPI(Number(id));
            setBeneficiaryDetails(data);
        } catch (err: unknown) {
            console.error(err);
        }
    }, [setBeneficiaries]);

    const account = beneficiaryAccounts?.find((account) => account)

    const fetchAccountBeneficiary = useCallback(async () => {
        try {
            const { data } = await getBeneficiariesBankAccount(Number(id))
            
            setAccountBeneficiary(data.results.sort((a, b) => b.id - a.id));
        }
        catch (err) {
        }

    }, [accountBeneficiary, beneficiaryAccounts, account, id])


    const payload = useMemo(() => {
        return {
            type: account?.type,
            agency: account?.agency,
            account_number: account?.account_number,
            account_number_digit: account?.account_number_digit,
            profile: account?.profile,
            beneficiary: account?.beneficiary,
            bank: account?.bank,
            status: "reproved",
            active: false,
        }
    }, [accountBeneficiary, account, beneficiaryAccounts])


    const fetchPatchAccountBeneficiary = useCallback(
        async () => {
            setLoading(true);
            try {
                await fetchBeneficiary();
                await fetchBeneficiaries()
                await patchBeneficiaryBankAccount(Number(account?.id), payload as IbeneficiaryBankAccount)
                fetchAccountBeneficiary()
                setappReproveAccountBeneficiaryModal(false)
                setLoading(false);
                notifySuccess();
                resetForm();
            } catch (err: unknown) {
                console.error(err);
                setLoading(false);
                notifyError();
            }
        },
        [fetchBeneficiary, accountBeneficiary, account, beneficiaryAccounts, fetchAccountBeneficiary]
    );

    useEffect(() => {
        fetchBanks();
        fetchAccountBeneficiary()
    }, []);


    return (
        <Modal
            style={{ zIndex: 6 }}
            modalHeading="Reprovar Conta"
            size="sm"
            open={open}
            onRequestClose={() => {
                onClose();
                resetForm();
            }}
            primaryButtonText="Sim, Reprovar"
            secondaryButtonText="Cancelar"
            onRequestSubmit={async () => {
                fetchPatchAccountBeneficiary();

            }}
        >
            <Margin mb={10} />
            <h3 style={{ textAlign: "center" }}>
                Deseja realmente Reprovar Conta abaixo?
            </h3>
            <Margin mb={10} />
            <Margin mb={40} />
            <>

                <h4>
                    Banco:{" "}
                    <span style={{ fontWeight: "bold" }}>{account?.bank}</span>
                </h4>
                <h4>
                    Agencia:
                    <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {account?.agency || "Não cadastrado"}
                    </span>
                </h4>
                <h4>
                    Conta:
                    <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {account?.account_number || "Não cadastrado"}
                    </span>
                </h4>

                <Margin mb={150} />
            </>
        </Modal>
    );
};

export default ReproveAccountModalBeneficiary;
