import ApiService from "../api";
import { IStatus } from "~/types";

type IResponseGetStatus = {
  count: number;
  next: null;
  previous: null;
  results: IStatus[];
};

export type ISendUpdateStatus = {
  name: string;
  color: string;
  active: boolean | undefined;
};

// export type ISendNewAreaBody = {
//   name: string;
//   active?: boolean;
// };

// export const postAreaAPI = (data: ISendNewAreaBody) => {
//   return ApiService.HttpPost({
//     route: "attend/attend-area/",
//     body: data,
//     customHeaders: { Authorization: `Bearer ${token}` },
//   });
// };

export const getStatusAPI = () => {
  return ApiService.HttpGet<IResponseGetStatus>({
    route: "attend/attend-status/",
    token: true,
  });
};

export const putStatusAPI = (payload: ISendUpdateStatus, id: number) => {
  return ApiService.HttpPut<IStatus>({
    route: `attend/attend-status/${id}/`,
    token: true,
    body: payload,
  });
};

export const postStatusAPI = (payload: ISendUpdateStatus) => {
  return ApiService.HttpPost<IStatus>({
    route: `attend/attend-status/`,
    token: true,
    body: payload,
  });
};
