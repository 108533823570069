import ApiService from "../api";
import { BankAccount, IFund, PixKey } from "~/types";

export type IBodyBeneficiary = {
  id?: number;
  name: string;
  document_number: string;
  bank_account?: BankAccount[];
  pix_keys?: {
    type: string;
    key: string;
    status?: string;
  }[];
  active?: boolean;
  profile?: number;
  status?: string;
  raw_data?: {
    pending_funds_approvations?: boolean;
  };
};

export type IBodyPatchBeneficiary = {
  id?: number;
  name?: string;
  document_number?: string;
  pix_keys?: {
    type?: string;
    key?: string;
    status?: string;
  }[];
  active?: boolean;
  profile?: number;
  status?: string;
  raw_data?: {
    pending_funds_approvations?: boolean;
  };
};

export type IBeneficiariesReponse = {
  results: IBeneficiary[];
};

export type IBeneficiaryFundBody = {
  id?: number;
  beneficiary: number;
  fund: IFund | number;
  raw_data: {
    status: string;
  };
};

export type IBeneficiaryFundPatch = {
  beneficiary?: number;
  fund?: IFund;
  raw_data: {
    status: string;
  };
};


export type IBeneficiaryReponse = {
  id: number;
  deleted: Date | null;
  deleted_by_cascade: boolean;
  active: boolean;
  created_on: Date;
  updated_on: Date;
  beneficiary: number;
  fund: IFund | number;
  raw_data: {
    status: string;
  };
};

interface IBeneficiary {
  id: number;
  deleted: string;
  deleted_by_cascade: boolean;
  name: string;
  document_number: string;
  bank_account: BankAccount[];
  pix_keys: PixKey[];
  active: boolean;
  created_on: string;
  updated_on: string;
  profile: number;
  status: string;
  raw_data?: {
    pending_funds_approvations?: boolean;
  };
}

export type IFundBeneficiary = {
  id: number;
  deleted: Date | null;
  deleted_by_cascade: boolean;
  active: boolean;
  created_on: Date;
  updated_on: Date;
  beneficiary: IBeneficiary;
  fund: IFund;
  raw_data: {
    status: string;
  };
};

export type IBeneficiaryFund = {
  id: number;
  deleted: Date | null;
  deleted_by_cascade: boolean;
  active: boolean;
  created_on: Date;
  updated_on: Date;
  beneficiary: IBeneficiary;
  fund: IFund;
  raw_data: {
    status: string;
  };
};

type IbeneficiarisFundResults = {
  results: IBeneficiaryReponse[];
};


export type IbeneficiaryBankAccount = {
  type?: string;
  agency: string
  account_number: string
  account_number_digit: string
  profile: number
  fund?: number
  beneficiary: number
  bank: number;
  status: string
  active?: boolean
}


export type IBankAccountResponse = {
  account_number: string;
  account_number_digit: string;
  active: boolean;
  agency: string;
  bank: number;
  beneficiary: number;
  created_on: string;
  deleted: boolean | null;
  deleted_by_cascade: boolean;
  fund: number | null;
  id: number;
  profile: number;
  status: string;
  type: string;
  updated_on: string;
}

export type IBeneficiaryPixKey = {
  type: string;
  key: string;
  active: boolean;
  profile: number;
  fund?: number;
  beneficiary?: number
}
export type IBeneficiariesPixKey =
  {
    id: number,
    deleted: number,
    deleted_by_cascade: false,
    type: string,
    key: string,
    active: true,
    created_on: string,
    updated_on: string,
    profile: 72,
    fund?: number,
    beneficiary: number
  }


export const postBeneficiaryAPI = (data: IBodyBeneficiary) => {
  return ApiService.HttpPost<IBodyBeneficiary>({
    route: "payment/payment-beneficiary/",
    body: data,
    token: true,
  });
};

export const getBeneficiariesAPI = () => {
  return ApiService.HttpGet<IBeneficiariesReponse>({
    route: "payment/payment-beneficiary/",
    token: true,
  });
};

export const deleteBeneficiaryAPI = (id: number) => {
  return ApiService.HttpDelete({
    route: `payment/payment-beneficiary/${id}/`,
    token: true,
  });
};

export const getBeneficiaryAPI = (id: number) => {
  return ApiService.HttpGet<IBeneficiary>({
    route: `payment/payment-beneficiary/${id}/`,
    token: true,
  });
};

export const patchBeneficiaryAPI = (
  id: number,
  data: IBodyPatchBeneficiary
) => {
  return ApiService.HttpPatch<IBodyBeneficiary>({
    route: `payment/payment-beneficiary/${id}/`,
    token: true,
    body: data,
  });
};

export const postBeneficiaryFundAPI = (data: IBeneficiaryFundBody) => {
  return ApiService.HttpPost({
    route: `payment/beneficiary-fund/`,
    token: true,
    body: data,
  });
};


export const getBeneficiaryFundAPI = () => {
  return ApiService.HttpGet<IbeneficiarisFundResults>({
    route: `payment/beneficiary-fund/`,
    token: true,
    params: {},
  });
};

export const getBeneficiaryFundWIDAPI = (id: number) => {
  return ApiService.HttpGet<{ results: IBeneficiaryFund[] }>({
    route: `payment/beneficiary-fund/`,
    token: true,
    params: {
      beneficiary: id,
      expand: "fund",
    },
  });
};

export const getBeneficiaryFundFilteredPerFundAPI = (id: number) => {
  return ApiService.HttpGet<{ results: IBeneficiaryFund[] }>({
    route: `payment/beneficiary-fund/`,
    token: true,
    params: {
      fund: id,
      expand: "beneficiary",
    },
  });
};

export const patchBeneficiaryFundAPI = (
  id: number,
  data: IBeneficiaryFundPatch
) => {
  return ApiService.HttpPatch<IbeneficiarisFundResults>({
    route: `payment/beneficiary-fund/${id}/`,
    body: data,
    token: true,
  });
};

////////////////////////////////////CONTA BANCARIA BENEFICIARIO\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

export const postBeneficiaryBankAccount = (data: IbeneficiaryBankAccount) => {
  return ApiService.HttpPost<IbeneficiaryBankAccount>({
    route: `payment/bank-account/`,
    body: data,
    token: true

  })
}

export const patchBeneficiaryBankAccount = (id: number, data: IbeneficiaryBankAccount) => {
  return ApiService.HttpPatch<IbeneficiaryBankAccount>({
    route: `payment/bank-account/${id}/`,
    body: data,
    token: true
  })
}
export const getBeneficiaryBankAccount = (id: number) => {
  return ApiService.HttpGet<{ results: IBankAccountResponse[] }>({
    route: `payment/bank-account/${id}/`,
    token: true
  })
}

export const getBeneficiariesBankAccount = (beneficiary?: number) => {

  return ApiService.HttpGet<{ results: IBankAccountResponse[] }>({
    route: `payment/bank-account/`,
    params: {
      expand: "beneficiary,bank",
      beneficiary
    },
    token: true
  })
}

export const getBeneficiariesDefaultBankAccount = () => {
  return ApiService.HttpGet<{ results: IBankAccountResponse[] }>({
    route: `payment/bank-account/`,
    token: true
  })
}

export const deleteBeneficiaryBankAccount = (id: number) => {
  return ApiService.HttpDelete({
    route: `payment/bank-account/${id}/`,
    token: true
  })
}


////////////////////////////////////CHAVE PIX BENEFICIARIO\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


export const postBeneficiarykeyPIX = (data: IBeneficiaryPixKey) => {
  return ApiService.HttpPost<IBeneficiaryPixKey>({
    route: `payment/pix/`,
    body: data,
    token: true

  })
}
export const patchBeneficiarykeyPIX = (id: number, data: IBeneficiaryPixKey) => {
  return ApiService.HttpPatch<IBeneficiaryPixKey>({
    route: `payment/pix/${id}/`,
    body: data,
    token: true
  })
}
export const getBeneficiarykeyPIX = (id: number) => {
  return ApiService.HttpGet<{ results: IBeneficiariesPixKey[] }>({
    route: `payment/pix/${id}/`,
    token: true
  })
}

export const getBeneficiarieskeyPIX = (beneficiary: number) => {
  return ApiService.HttpGet<{ results: IBeneficiariesPixKey[] }>({
    route: `payment/pix/`,
    params: {
      expand: "beneficiary",
      beneficiary,
    },
    token: true
  })
}

export const deleteBeneficiarykeyPIX = (id: number) => {
  return ApiService.HttpDelete({
    route: `payment/pix/${id}/`,
    token: true
  })
}
