import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useArea from "~/hooks/useArea";
import {
  getAreaTypesAPI,
  ISendNewAreaTypeBody,
  postAreaTypeAPI,
} from "~/services/api/areaTypes";
import Select from "react-select";
import Label from "~/components/Label";
import useAttendType from "~/hooks/useType";
import { getAttendTypeAPI } from "~/services/api/type";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IAttendType } from "~/types";
import useAreaType from "~/hooks/useAreaType";
import { toast } from "react-toastify";

type Values = {
  attend_type: string;
};

const initialValues: Values = {
  attend_type: "",
};

const validationSchema = Yup.object({
  attend_type: Yup.string().required("Tipo de atendimento é obrigatório"),
});

const AssociateAreaTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { areaDetails } = useArea();

  const { setAreaTypes } = useAreaType();

  const { setAttendTypes, attendTypes } = useAttendType();

  const notifySuccess = () =>
    toast.success("Tipo de atendimento atribuído com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível atribuir o tipo de atendimento. Tente novamente mais tarde."
    );

  const { setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const attendTypesOptions = useMemo(() => {
    return attendTypes
      ?.filter((attendType: IAttendType) => attendType.active)
      .map((attendType: IAttendType) => ({
        value: String(attendType.id),
        label: `${attendType.name}`,
      }));
  }, [attendTypes]);

  type SelectOption = {
    value: string;
    label: string;
  };
  const handleSelectAttendType = (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      setFieldValue("attend_type", selectedOption.value);
    }
  };

  const fetchAttendTypes = useCallback(async () => {
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAttendTypes]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setAreaTypes]);

  const payloadAssociateNewAttendType = useMemo(() => {
    return {
      type: Number(values.attend_type),
      area: Number(areaDetails?.id),
    };
  }, [values.attend_type, areaDetails]);

  const fetchAssociateAttendType = useCallback(
    async (payload: ISendNewAreaTypeBody) => {
      setLoading(true);
      try {
        await postAreaTypeAPI(payload);
        await fetchAreaTypes();
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [fetchAreaTypes, setLoading]
  );

  useEffect(() => {
    fetchAttendTypes();
  }, []);

  return (
    <Modal
      modalHeading="Atribuir tipo de atendimento"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Concluir"
      primaryButtonDisabled={values.attend_type === ""}
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchAssociateAttendType(payloadAssociateNewAttendType);
        onClose();
      }}
    >
      {/* {true && (
        <InlineNotification
          title="Erro!"
          // subtitle={error}
          subtitle="Não foi possivel atribuir o tipo de atendimento"
          hideCloseButton
          kind="error"
          lowContrast
        />
      )} */}
      <Margin mt={20} />
      <Label text="Selecione um tipo de atendimento" />
      <Select
        options={attendTypesOptions}
        onChange={handleSelectAttendType}
        placeholder="Pesquisar ou selecionar um tipo de atendimento..."
      />{" "}
      <Margin mb={350} />
    </Modal>
  );
};

export default AssociateAreaTypeModal;
