import { useFormik } from "formik";
import { Modal, Notification, Radio } from "prosperita-dumbo-react";
import { useCallback, useState } from "react";
import Margin from "~/components/Margin";
import useLoading from "~/hooks/useLoading";
import useUser from "~/hooks/useUser";
// postNewPasswordAPI ISendNewPassword
import { postForgotPassword } from "~/services/api/password";
import { formatCPFCNPJ } from "~/services/utils";
import * as Yup from "yup";

type ImodalNewPassword = {
  open: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

type INotification = {
  status: "success" | "warning" | "info" | "error";
  message: string;
  title: string;
  active: boolean;
};

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(/[0-9]/, "A senha deve conter pelo menos um número")
    .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "A senha deve conter pelo menos um caractere especial"
    )
    .required("A senha é obrigatória"),
});

export const ChangeNewPassword = (props: ImodalNewPassword) => {
  const { onClose, open } = props;
  const { setLoading } = useLoading();

  const [isRadioSelected, setisRadioSelected] = useState<boolean>(false);

  const [notificationDetails, setNotificationDetails] = useState<INotification>(
    {
      status: "info",
      message: "",
      title: "",
      active: false,
    }
  );

  const { values, resetForm } = useFormik({
    validationSchema,
    initialValues: {
      changepassword: "",
      actual_password: "",
      password: "",
      user: "",
    },
    onSubmit: () => {},
  });

  // const minLength = values.password.length >= 8;
  // const hasNumber = /[0-9]/.test(values.password);
  // const hasUpperCase = /[A-Z]/.test(values.password);
  // const hasLowerCase = /[a-z]/.test(values.password);
  // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(values.password);

  const { userProfile, setUpdateChangePasswordModal } = useUser();

  // const payloadAssociateNewAttendAreaProfile = useMemo(() => {
  //     return {
  //         current_password: values.actual_password,
  //         new_password: values.password,
  //     };
  // }, [values]);

  const fetchForgotPassword = useCallback(async () => {
    setLoading(true);
    try {
      await postForgotPassword({ user: String(userProfile?.document_number) });
      setUpdateChangePasswordModal(false);
      setLoading(false);
      setisRadioSelected(false);
      setNotificationDetails({
        status: "success",
        message:
          "A redefinição automatica da senha foi gerada e eviada ao usuário com sucesso",
        title: "Senha gerada automaticamente com sucesso",
        active: true,
      });
      setTimeout(() => {
        setNotificationDetails((prev) => ({
          ...prev,
          active: false,
        }));
      }, 5000);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      setisRadioSelected(false);
      setNotificationDetails({
        status: "error",
        message:
          "Não foi possível gerar a senha automatica no momento, tente novamente mais tarde ou contate o administrador do sistema",
        title: "Falha ao redefinir senha gerada automaticamente",
        active: true,
      });
      setTimeout(() => {
        setNotificationDetails((prev) => ({
          ...prev,
          active: false,
        }));
      }, 5000);
    }
  }, [values]);

  // const fetchAssociateAttendAreaProfile = useCallback(
  //     async (payload: ISendNewPassword) => {
  //         setLoading(true);
  //         try {
  //             await postNewPasswordAPI(payload);
  //             setUpdateChangePasswordModal(false)
  //             setNotificationDetails({
  //                 status: "success",
  //                 message: 'A senha foi redefinida com sucesso',
  //                 title: "Senha criada com sucesso",
  //                 active: true
  //             })
  //             setTimeout(() => {
  //                 setNotificationDetails((prev) => ({
  //                     ...prev,
  //                     active: false
  //                 }));
  //             }, 5000)
  //             setTimeout(
  //                 () => setNotificationDetails((prev) => ({ ...prev, active: false })),
  //                 5000
  //             );
  //             resetForm();
  //             setLoading(false);
  //         } catch (err: unknown) {
  //             console.error(err);
  //             setNotificationDetails({
  //                 status: "error",
  //                 message: 'Não foi possível criar a nova senha no momento, tente novamente mais tarde ou contate o administrador do sistema',
  //                 title: "Falha ao criar senha",
  //                 active: true
  //             })
  //             setTimeout(
  //                 () => setNotificationDetails((prev) => ({ ...prev, active: false })),
  //                 10000
  //             );
  //             resetForm()
  //             setLoading(false);
  //         }
  //     },
  //     [setLoading, resetForm]
  // );

  return (
    <>
      <Modal
        open={open}
        radius
        background="#f4f4f4"
        onClose={() => {
          onClose && onClose();
          resetForm();
          setisRadioSelected(false);
        }}
        overflow
        height={"300px"}
        buttons={[
          {
            label: "Cancel",
            kind: "ghost",
            onClick: () => {
              setUpdateChangePasswordModal(false);
              resetForm();
              setisRadioSelected(false);
            },
          },
          {
            label: "Redefinir senha",
            kind: "primary",
            onClick: () => isRadioSelected && fetchForgotPassword(),
          },
        ]}
        label={
          <>
            <div>
              <h4 className="m-0 p-0">Redefinir senha para o usuário:</h4>
              <Margin mt={10} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 font-[700] font-sans text-[20px]"
              >
                {userProfile?.name}
              </h4>
              <Margin mt={6} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 font-[400] text-[18px]"
              >
                {formatCPFCNPJ(String(userProfile?.document_number))}
              </h4>
              <Margin mt={5} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 text-[15px] font-[450]"
              >
                {userProfile?.user.email}
              </h4>
            </div>
          </>
        }
      >
        <div className="w-[100%] h-[2px] bg-[#c6c6c6] mt-1"></div>
        <div className="w-[95%] justify-center mx-auto mt-3">
          <Radio
            label="Gerar uma senha automaticamente"
            InvalidText=""
            texthelp="Uma senha provisória será enviada para o seu e-mail."
            checked={isRadioSelected}
            onClick={() => setisRadioSelected((prev) => !prev)}
          />
        </div>
      </Modal>
      <Notification
        zindex={notificationDetails.status === "error" ? "10" : "8"}
        top={"130px"}
        right="20px"
        onClose={() =>
          setNotificationDetails((prev) => ({
            ...prev,
            active: false,
          }))
        }
        noRadius
        status={notificationDetails.status}
        actived={notificationDetails.active}
        title={notificationDetails.title}
        message={notificationDetails.message}
      >
        <p className="mx-auto text-center m-0 mt-3 py-0">00/00/0000 00:00:00</p>
      </Notification>
    </>
  );
};
