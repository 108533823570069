import { Modal } from 'prosperita-dumbo-react'
import useUser from '~/hooks/useUser';

type ImodalNewPassword = {
    open: boolean;
    onClose?: () => void;
    onSubmit?: () => void
}

export const ConfirmedForgotPasswordModal = (props: ImodalNewPassword) => {

    const { onClose, open, onSubmit } = props

    const {
        setIsConfirmSendPasswordModalOpen,
        verifiedEmail
    } = useUser()

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                height='255px'
                overflow
                label={<h2>Uma senha provisória será enviada para o seu e-mail abaixo.</h2>}
                buttons={[
                    {
                        label: "Cancelar",
                        onClick: () => setIsConfirmSendPasswordModalOpen(false),
                        kind: "ghost"
                    },
                    {
                        label: "Enviar",
                        onClick: () => onSubmit && onSubmit(),
                        kind: "primary"
                    }
                ]}
            >
                <div className='w-[99%] mx-auto'>
                    <p className='text-center font-[600] mt-3'>{verifiedEmail}</p>
                </div>

            </Modal >
        </>
    )
}
